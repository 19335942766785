import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  loginImage,
  jammin,
  close,
  sign_logo,
  divider,
  google,
  eyeoff,
  eyeon,
} from "../../assets/images";
import Login from "../login/login";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import GoogleSignIn from "../../google";

function Signup({ onClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");
  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [mob, setMob] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const [otp, setotp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [email, password, re_password, last_name, first_name, mob]);

  const handleLoginClick = () => {
    setLoginPopupVisible(true);
    // onClose();
  };

  const handleshow1 = () => {
    setShow1(!show1);
  };
  const handleshow = () => {
    setShow(!show);
  };

  const policy1 = () => {
    window.scrollTo(0, 0);

    navigate(`/privacy_policy`);
  };
  const terms1 = () => {
    window.scrollTo(0, 0);

    navigate(`/terms`);
  };

  const handleClosePopup = () => {
    onClose();
    setLoginPopupVisible(false);
  };

  const signupclick = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      if (validatePassword()) {
        try {
          const response = await axios.post(
            `${config.apiUrl}/users/auth/signup`,
            {
              email,
              password,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            setotp(true);
            // localStorage.setItem('access_token', response.data.access_token);
            // navigate('/user');
          } else {
            // setError("Invalid email or password.");
          }
        } catch (error) {
          setError(
            error.response?.data?.message ||
              "An error occurred. Please try again."
          );
        }
      } else {
        setError("Passwords do not match.");
      }
    } else {
      setIsFormValid(false);
    }
  };

  const validateForm = () => {
    return (
      email.trim() !== "" &&
      password.trim() !== "" &&
      re_password.trim() !== "" &&
      last_name.trim() !== "" &&
      first_name.trim() !== "" &&
      mob.trim() !== ""
    );
  };

  const validatePassword = () => {
    return password === re_password;
  };

  return (
    <MainContainer>
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <ContentContainer>
        <LeftColumn>
          <StyledImg loading="lazy" src={loginImage} />
        </LeftColumn>
        <RightColumn>
          <FormContainer>
            <Header>
              <CloseButton onClick={onClose}>
                <img loading="lazy" src={close} alt="Close" />
              </CloseButton>
              <LogoContainer>
                <LogoImg loading="lazy" src={sign_logo} alt="Logo" />
              </LogoContainer>
              <LoginTitle>Sign Up to your account</LoginTitle>
              {config.apiUrl === "https://api.jammin.ai" && (
                <>
                  <Gbutton>
                    <div>
                      <GoogleSignIn />
                    </div>
                  </Gbutton>
                  <DividerContainer>
                    <DividerIcon loading="lazy" src={divider} />
                    <DividerText>or</DividerText>
                    <DividerIcon loading="lazy" src={divider} />
                  </DividerContainer>
                </>
              )}
            </Header>
            <Form>
              <HorizontalInputRow>
                <InputRow>
                  <InputField
                    type="text"
                    placeholder="First Name"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </InputRow>
                <InputRow>
                  <InputField
                    type="text"
                    placeholder="Last Name"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </InputRow>
              </HorizontalInputRow>
              <HorizontalInputRow>
                <InputRow>
                  <InputField
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputRow>
                <InputRow>
                  <InputField
                    type="tel"
                    placeholder="Mobile Number"
                    value={mob}
                    onChange={(e) => setMob(e.target.value)}
                  />
                </InputRow>
              </HorizontalInputRow>
              <InputRow10>
                <InputField10
                  type={show1 ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {show1 ? (
                  <EyeIcon10
                    loading="lazy"
                    onClick={handleshow1}
                    src={eyeoff}
                  />
                ) : (
                  <EyeIcon10 loading="lazy" onClick={handleshow1} src={eyeon} />
                )}
              </InputRow10>
              <InputRow10>
                <InputField10
                  type={show ? "text" : "password"}
                  placeholder="Re-enter Password"
                  value={re_password}
                  onChange={(e) => setRePassword(e.target.value)}
                />
                {show ? (
                  <EyeIcon10 loading="lazy" onClick={handleshow} src={eyeoff} />
                ) : (
                  <EyeIcon10 loading="lazy" onClick={handleshow} src={eyeon} />
                )}
              </InputRow10>
              {error && <ErrorToast>{error}</ErrorToast>}
              <TermsText>
                By continuing, you agree to Jammin’s
                <TermsLink onClick={policy1}> Privacy Policy </TermsLink>
                and
                <TermsLink onClick={terms1}> Terms of Use</TermsLink>.
              </TermsText>
              <LoginButton onClick={signupclick} filled={isFormValid}>
                Sign up
              </LoginButton>
              {/* <DividerContainer>
                <DividerIcon loading="lazy" src={divider} />
                <DividerText>or</DividerText>
                <DividerIcon loading="lazy" src={divider} />
              </DividerContainer>
              <Gbutton>
                <div>
                  <GoogleSignIn />
                </div>
              </Gbutton> */}
              <SignupLink>
                Already have an account?{" "}
                <SignupNow onClick={handleLoginClick}>Login</SignupNow>
              </SignupLink>
            </Form>
          </FormContainer>
        </RightColumn>
      </ContentContainer>
      {isLoginPopupVisible && (
        <PopupOverlay>
          <Login onClose={handleClosePopup} />
        </PopupOverlay>
      )}

      {otp && (
        <PopupOverlay>
          <OTPVerification
            onClose={() => setotp(false)}
            email={email}
            password={password}
          />
        </PopupOverlay>
      )}
    </MainContainer>
  );
}

function OTPVerification({ onClose, email, password }) {
  const [otp1, setOtp1] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const otpclick = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${config.apiUrl}/users/auth/confirm`,
        {
          email: email,
          code: otp1,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.setItem("access_token", response.data.access_token);
        navigate("/user");
      } else if (response.status === 400) {
        setError("Invalid Otp");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <VerificationContainer>
      <HeaderContainer>
        <HeaderText>Verify OTP</HeaderText>
        <CloseButton2 onClick={onClose}>
          <CloseIcon loading="lazy" src={close} alt="Close" />
        </CloseButton2>
      </HeaderContainer>
      <InstructionText>
        Please enter the one-time password sent to your Email address
      </InstructionText>
      <form>
        <OTPInput
          type="text"
          id="otpInput"
          placeholder="Enter OTP"
          aria-label="Enter OTP"
          value={otp1}
          onChange={(e) => setOtp1(e.target.value)}
        />
        {error && <ErrorToast>{error}</ErrorToast>}
        <VerifyButton type="submit" onClick={otpclick}>
          Verify OTP
        </VerifyButton>
      </form>
    </VerificationContainer>
  );
}

const EyeIcon = styled.img`
  position: absolute;
  right: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Gbutton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  white-space: nowrap;
  @media (max-width: 991px) {
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
`;

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px; // Add some space below the close button
`;

const CloseButton1 = styled.button`
  position: absolute;
  top: 10px;
  right: 12px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`;

const LogoImg = styled.img`
  max-width: 150px;
`;

const MainContainer = styled.section`
  border-radius: 12px;
  background-color: #fff;
  max-width: 864px;
  margin: 0 auto;
  padding: 0; /* Remove padding to eliminate space */
  overflow: hidden; /* Ensure content does not overflow */
  @media (max-width: 991px) {
    margin-left: 5px;
    margin-right: 5px;
    min-width: 95%;
    padding:4px;
  }
`;
const ErrorToast = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 8px;
`;

const DividerContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #ababab;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const DividerIcon = styled.img`
  width: 69px;
  aspect-ratio: 1;
  border: 1px solid #ababab;
  object-fit: cover;
  object-position: center;
  height: 2px;
  background: none;
  border: none;
`;

const DividerText = styled.span`
  font-family: Inter, sans-serif;
  align-self: stretch;
  padding: 0 1px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 28px;
  right: 12px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`;

const CloseButton2 = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 40px;
`;
const TermsText = styled.p`
  margin-top: 12px;
  color: #3f3f3f;
  font: 400 10px/16px Poppins, sans-serif;
  text-align: center;
  align-self: center;
`;

const TermsLink = styled.a`
  color: rgba(63, 63, 63, 1);
  text-decoration: underline;
  cursor: pointer;
`;

const HorizontalInputRow = styled.div`
  display: flex;
  gap: 6px;
  @media (max-width:991px){
  width 100%;
  }
`;

const ContentContainer = styled.section`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 0;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
   }
`;

const InputRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 991px) {
    width:100%;
  }
`;

const InputRow10 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  gap: 12px;
  margin-right: 0px;
  position: relative; /* Add this */
`;

const InputField10 = styled.input`
  border: 1px solid rgba(236, 236, 236, 1);
  border-radius: 4px;
  padding: 17px 16px;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  padding-right: 50px; /* Add extra padding to make space for the eye icon */
`;

const EyeIcon10 = styled.img`
  position: absolute;
  right: 10px;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const InputRow1 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  gap: 12px;
  margin-right: 15px;
`;

const InputField = styled.input`
  border: 1px solid rgba(236, 236, 236, 1);
  border-radius: 4px;
  padding: 17px 16px;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  @media(max-width:991px){
    width:80%;
    padd
  }
`;

const LeftColumn = styled.aside`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%; /* Ensure the image fills the entire space */
  object-fit: cover;
`;

const RightColumn = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    height: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 0px;
    width:90%
  }
`;

// const Header = styled.header`
//   display: flex;
//   flex-direction: column;
//   padding-left: 60px;
//   @media (max-width: 991px) {
//     padding-left: 60px;
//   }
// `;

// const LogoContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 261px;
//   max-width: 100%;
//   margin-right: auto;
//   padding-left: 90px;
// `;

// const LogoImg = styled.img`
//   width: 128px;
//   aspect-ratio: 6.25;
//   margin-top: 6px;
// `;

const LoginTitle = styled.h1`
  font: 600 20px/24px Poppins, sans-serif;
  color: #0e0402;
  margin: 13px 0 0px 10px;

  text-align: center;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Form = styled.form`
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    gap: 20px;
    width:100%;
  }
`;

const TextInput = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 16px;
  padding: 17px 16px;
  color: #ababab;
  margin-right: 10px;
  font: 400 12px/22px Poppins, sans-serif;
  &:first-of-type {
    margin-top: 34px;
  }
  @media (max-width: 991px) {
    gap: 20px;
  }
`;

const ForgotPasswordLink = styled.a`
  color: #51606f;
  text-align: center;
  align-self: flex-end;
  margin-top: 12px;
  margin-right: 10px;
  font: 500 12px Poppins, sans-serif;
  cursor: pointer;
`;

const LoginButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.filled ? "#2373ED" : "#ececec")};
  margin-top: 12px;
  margin-left: 0px;
  padding: 17px 30px;
  margin-right: 10px;

  color: ${(props) => (props.filled ? "#ffff" : "#ababab")};
  font: 500 18px Inter, sans-serif;
  text-align: center;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const AlternativeLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 20px;
  color: #ababab;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 991px) {
    padding: 0 2px;
  }
`;

const SocialLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 2px;
`;

const GoogleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const FacebookButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    display: none;
  }
`;

const SignupLink = styled.p`
  text-align: center;
  color: #fffff;
  margin-top: 20px;
  margin-bottom: 20px;
  font: 400 12px/120% Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-top: 10px;
  }
`;

const SignupNow = styled.span`
  font-weight: 600;
  color: rgba(32, 201, 151, 1);
  cursor: pointer;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const OTPInput = styled.input`
  border-radius: 4px;
  border: 1px solid #ececec;
  background-color: #fff;
  margin-top: 15px;
  color: #676767;
  padding: 17px 16px;
  font: 500 16px/137.5% "SF Pro Text", -apple-system, Roboto, Helvetica,
    sans-serif;
  width: 90%;
`;

const VerifyButton = styled.button`
  justify-content: center;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 44px;
  color: #fff;
  text-align: center;
  padding: 17px 10px;
  font: 500 18px Inter, sans-serif;
  width: 80%;
  border: none;
  margin-left: 35px;
  cursor: pointer;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-size: 20px;
  color: #0e0402;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.14px;
  line-height: 100%;
  width: 100%;
`;

const HeaderText = styled.h1`
  font-family: Poppins, sans-serif;
  text-align: center;
  margin-left: 100px;
  justify-content: center;
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  align-self: flex-start;
`;

const VerificationContainer = styled.main`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  padding: 15px 16px 52px;
  margin: auto; /* Ensure the container is centered on the page */
  @media (max-width: 991px) {
    padding: 10px 8px;
    justify-content: center;
  }
`;

const InstructionText = styled.p`
  color: #0e0402;
  text-align: center;
  letter-spacing: -0.13px;
  margin-top: 1px;
  font: 13px/130% Poppins, sans-serif;
  width: 100%;
`;

export default Signup;
