import React, { useEffect, useRef, useImperativeHandle } from "react";

const VideoPlayer = ({ videoUrl, onTimeUpdate, playerRef, isPlaying, playerRef2 }) => {
  const iframeRef = useRef(null);
  const intervalRef = useRef(null);

  const extractVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/|v\/)?([^&?\/\s]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      isPlaying(true);
      intervalRef.current = setInterval(() => {
        if (playerRef.current && typeof playerRef.current.getCurrentTime === "function") {
          const currentTime = playerRef.current.getCurrentTime();
          if (onTimeUpdate) {
            onTimeUpdate(currentTime);
          }
        }
      }, 1000);
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      isPlaying(false);
      clearInterval(intervalRef.current);
    } else if (event.data === window.YT.PlayerState.ENDED) {
      isPlaying(false);
      clearInterval(intervalRef.current);
    }
  };

  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
    }
    playerRef.current = new window.YT.Player(iframeRef.current, {
      videoId: extractVideoId(videoUrl),
      events: {
        onStateChange: handleStateChange,
      },
    });
  };

  useEffect(() => {
    if (window.YT && window.YT.Player) {
      // API is already loaded, initialize the player
      initializePlayer();
    } else {
      // Dynamically load the YouTube IFrame API
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);

      window.onYouTubeIframeAPIReady = () => {
        initializePlayer();
      };
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      clearInterval(intervalRef.current);
    };
  }, [videoUrl]);

  // Expose restartVideo function to the parent using useImperativeHandle
  useImperativeHandle(playerRef2, () => ({
    restartVideo: () => {
      if (playerRef.current && typeof playerRef.current.seekTo === "function") {
        playerRef.current.seekTo(0); // Seek the video to the start
        playerRef.current.playVideo(); // Play the video from the start
      }
    },
  }));

  return (
    <div className="video-player">
      <style>
        {`
          .video-player {
            position: relative;
            width: 461px;
            height: 300px;
            overflow: hidden;
          }
          .video-player iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            margin: 0;
            padding: 0;
          }
        `}
      </style>
      <div
        ref={iframeRef}
        id="youtube-iframe"
        style={{ width: "100%", height: "100%" }}
      ></div>
    </div>
  );
};

export default React.forwardRef(VideoPlayer);
