import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getToken,clearToken} from '../utils';
import config from '../../config';
import {  useNavigate } from "react-router-dom";

// Styled component for the card container
const CardContainer = styled.div`
  position: relative;
  background-color: white;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 16px;
 

`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #aaa;
`;

const TextInput = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  padding: 12px 16px;
  color: #212529;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const CreatePlaylistPopup = ({ onClose, onCreatePlaylistSuccess }) => {
  const [playlistName, setPlaylistName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const token = getToken();
  const navigate = useNavigate();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleCreatePlaylist = async () => {
 
   
    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists`,
        { name: playlistName }, // Request body with playlistName
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        onClose(); 
       
      }

    } catch (error) {
      if(error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } 
    }
  };

  return (
    <CardContainer>
      <CloseButton onClick={onClose}>×</CloseButton>
      <h2>Create New Playlist</h2>
      <TextInput
        type="text"
        placeholder="Enter Playlist Name"
        value={playlistName}
        onChange={(e) => setPlaylistName(e.target.value)}
      />
      <TextInput type="file" accept="image/*" onChange={handleImageUpload} />
      <Button onClick={handleCreatePlaylist}>Create Playlist</Button>
    </CardContainer>
  );
};

export default CreatePlaylistPopup;
