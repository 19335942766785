import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  lan_music,
  landing_guitar,
  close,
  white_close,
} from "../../assets/images";
import useSubscription from "./subscription";
import {
  getToken,
  clearToken,
  getsubscription_enddate,
  getsubscription_status,
} from "../utils";
import axios from "axios";
import { useLocation } from "react-router-dom";
import config from "../../config";
import WarningModal from "../landing/warning_popup";
import { useNavigate } from "react-router-dom";
import Setcard from "./set_card";

import After_set_cards from "./after_set_cards";

function PlanCard({ subscription }) {
  return (
    <Card className="plan-card">
      <Header className="plan-header">
        <PlanDetails className="plan-details">
          <PlanType className="plan-type">Free</PlanType>
          <PlanPrice2 className="plan-price">$0</PlanPrice2>
        </PlanDetails>
        {subscription === null ? (
          <CurrentPlanBadge className="current-plan-badge">
            Current Plan
          </CurrentPlanBadge>
        ) : (
          <CurrentPlanBadge className="current-plan-badge">
            Select Plan
          </CurrentPlanBadge>
        )}
      </Header>
      <FeaturesList className="features-list">
        <FeatureItem className="feature-item">5 songs</FeatureItem>
        <FeatureItem className="feature-item">No PDF Downloads</FeatureItem>
      </FeaturesList>
    </Card>
  );
}

function PlanCard1({ subscription }) {
  const token = getToken();
  const navigate = useNavigate();

  const sub = async () => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/subscription/`,
        {
          subscription_type: "yearly",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const checkoutUrl = response.data.checkout_url;

        // window.open(checkoutUrl, '_blank'); // Open URL in a new tab
        window.open(checkoutUrl);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };
  return (
    <Card className="plan-card">
      <Header1 className="plan-header">
        <PlanDetails className="plan-details">
          <PlanType className="plan-type">Yearly</PlanType>
          <PlanPrice2 className="plan-price">$149.99</PlanPrice2>
        </PlanDetails>
        {subscription === "yearly" ? (
          <CurrentPlanBadge className="current-plan-badge">
            Current Plan
          </CurrentPlanBadge>
        ) : (
          <CurrentPlanBadge className="current-plan-badge" onClick={sub}>
            Select Plan
          </CurrentPlanBadge>
        )}
      </Header1>
      <FeaturesList className="features-list">
        <FeatureItem1 className="feature-item">Unlimited Songs</FeatureItem1>
        <FeatureItem1 className="feature-item">
          Unlimited PDF Downloads
        </FeatureItem1>
      </FeaturesList>
    </Card>
  );
}

function PlanCard2({ subscription }) {
  const token = getToken();
  const navigate = useNavigate();

  const sub = async () => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/subscription/`,
        {
          subscription_type: "monthly",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const checkoutUrl = response.data.checkout_url;

        // window.open(checkoutUrl, '_blank'); // Open URL in a new tab
        window.open(checkoutUrl);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };
  return (
    <Card className="plan-card">
      <Header2 className="plan-header">
        <PlanDetails className="plan-details">
          <PlanType className="plan-type">Monthly</PlanType>
          <PlanPrice2 className="plan-price">$19.99</PlanPrice2>
        </PlanDetails>

        {subscription === "monthly" ? (
          <CurrentPlanBadge className="current-plan-badge">
            Current Plan
          </CurrentPlanBadge>
        ) : (
          <CurrentPlanBadge className="current-plan-badge" onClick={sub}>
            Select Plan
          </CurrentPlanBadge>
        )}
      </Header2>
      <FeaturesList className="features-list">
        <FeatureItem1 className="feature-item">Unlimited Songs</FeatureItem1>
        <FeatureItem1 className="feature-item">
          Unlimited PDF Downloads
        </FeatureItem1>
      </FeaturesList>
    </Card>
  );
}

const Card = styled.div`
  align-self: stretch;
  background-color: #fff;
  display: flex;
  min-width: 300px;
  max-height: 285px;
  flex-direction: column;
  // box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.5),
  //   0 4px 8px rgba(255, 255, 255, 0.5);
  border-radius:8px;
    

    @media (max-width: 991px) {
    width: 90%;
   
  }
`;

const Header = styled.header`
  background-color: #0072d5;
  display: flex;
  max-width: 328px;
  max-height: 187px;
  flex-direction: column;
  padding: 16px 12px;
  justify-content: center;     /* Center children vertically */
  align-items: center;  
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const Header1 = styled.header`
  background-color: #020f12;
  display: flex;
  max-width: 328px;
  max-height: 187px;
  flex-direction: column;
  padding: 16px 12px;
  justify-content: center;     /* Center children vertically */
  align-items: center; 
  border-top-left-radius: 7px;
  border-top-right-radius: 7px; 
`;

const Header2 = styled.header`
  background-color: #4542ca;
  display: flex;
  max-width: 328px;
  max-height: 187px;
  flex-direction: column;
  padding: 16px 12px;
  justify-content: center;     /* Center children vertically */
  align-items: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;  
`;

const PlanDetails = styled.div`
  flex-direction: column;
  color: #fff;
  justify-content: center;     /* Center children vertically */
  align-items: center;  
`;

const PlanType = styled.h2`
  letter-spacing: -0.28px;
  margin-top: 1px;
  font: 700 28px Montserrat, sans-serif;
  @media (max-width: 991px) {
   font-size:23px;
  }
`;

const PlanPrice = styled.h1`
  letter-spacing: -0.4px;
  margin-top: 4px;
  font: 600 40px Poppins, sans-serif;
`;

const PlanPrice2 = styled.h1`
  margin-top: -10px;
  margin-bottom: 10px;
  font: 600 40px Poppins, sans-serif;
`;

const PlanPrice1 = styled.div`
  letter-spacing: -0.4px;
  margin-top: 0px;
  font: Source sans pro, sans-serif;
  font-size: 12px;
`;

const CurrentPlanBadge = styled.button`
  border-radius: 999999px;
  background-color: #fff;
  color: #428bca;
  border: none;
  min-width: 159px;
  justify-content: center;
  padding: 14px 12px;
  font: 600 14px Poppins, sans-serif;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
  }
`;

const CurrentPlanBadge1 = styled.button`
  border-radius: 999999px;
  background-color: #fff;
  margin-top: 30px;
  max-width: 159px;
  color: #428bca;
  border: none;
  justify-content: center;
  padding: 14px 12px 14px 32px;
  font: 600 14px Poppins, sans-serif;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
  }
`;

const FeaturesList = styled.ul`
  display: flex;
  margin-top: 4px;
  max-width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: #3f3f3f;
  font-weight: 600;
  text-align: center;
  padding: 0 12px;
  list-style: none;
`;

const FeatureItem = styled.li`
  font-family: Source Sans Pro, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 12px 60px;
  border-top: 1px solid rgba(217, 217, 217, 1);

  &:first-child {
    border-top: none;
  }
`;

const FeatureItem1 = styled.li`
  font-family: Source Sans Pro, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 12px 30px;
  border-top: 1px solid rgba(217, 217, 217, 1);

  &:first-child {
    border-top: none;
  }
`;

const PlanCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
  @media (max-width: 991px) {
    width: 95%;
    flex-direction: column;
    gap: 16px;
    margin-right: 30px;
  }
`;

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

function Pricing2({ onClose }) {
  const subscription2 = useSubscription();
  const [down, setDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showcard, setshowcard] = useState(false);
  const [showyearcard, setshowyearcard] = useState(false);
  const [showaftercard, setshowaftercard] = useState(false);
  const end_date1 = getsubscription_enddate();
  const end_date = new Date(end_date1);
  const status = getsubscription_status();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  const formattedDate = end_date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  useEffect(() => {
    if (location.pathname === "/cards") {
      setTimeout(() => {
        const element = document.getElementById("pricing-section");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }

        setshowaftercard(true);
      }, 100);
    } else {
      setshowaftercard(false);
    }
  }, [location.pathname, navigate]);

  const downgrade1 = () => {
   // setDown(true);
  };

  const token = getToken();
  const sub = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}/subscription/`,
        {
          subscription_type: "yearly",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const checkoutUrl = response.data.checkout_url;
       
        window.open(checkoutUrl);
        setLoading(false);
        // setLoading(true);
        // navigate("/thankyou");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } 
      // else if (
      //   error.response &&
      //   error.response.status === 400 &&
      //   error.response.data.message === "No payment method set!"
      // ) {
      //   setshowcard(true);
      // }
    }
  };

  const sub1 = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}/subscription/`,
        {
          subscription_type: "monthly",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const checkoutUrl = response.data.checkout_url;
       
        window.open(checkoutUrl);
        setLoading(false);
        // setLoading(true);
        // navigate("/thankyou");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } 
      // else if (
      //   error.response &&
      //   error.response.status === 400 &&
      //   error.response.data.message === "No payment method set!"
      // ) {
      //   setshowcard(true);
      // } else if (
      //   error.response &&
      //   error.response.status === 400 &&
      //   error.response.data.message === "You are already on yearly plan"
      // ) {
      //   setshowyearcard(true);
      // }
    }
  };

  return (
    <MainContainer>
      <CloseButton onClick={onClose}>×</CloseButton>
      <Section>
        <Article>
          {/* <ImageWrapper>
            <Image loading="lazy" src={lan_music} alt="Musical instrument" />
          </ImageWrapper> */}
          <Content>
            <Content1>
              {subscription2 === null ? (
                <>
                  {isMobile ? (
                    <Title1>
                      Oops, you've reached
                      <br /> your limit
                    </Title1>
                  ) : (
                    <Title1>Oops, you've reached your limit</Title1>
                  )}
                </>
              ) : (
                <Title1>
                  Oops, you've reached your monthly limit of 50 songs
                </Title1>
              )}
            </Content1>
            <Title>Unlock Your Musical Potential</Title>
            <Description>
              Unleash your inner musician with Jammin.ai, the revolutionary
              platform that makes
              <br /> learning music fun and accessible! We offer a variety of
              pricing plans and content to
              <br /> fit your musical goals and budget.
            </Description>
          </Content>
        </Article>
      </Section>
      <PlansSection>
        <PlansWrapper>
          <Section1>
            <PlanCardsContainer>
              {/* <PlanCard subscription={subscription2} /> */}

              <Card className="plan-card">
                <Header className="plan-header">
                  <PlanDetails className="plan-details">
                    <PlanType className="plan-type">Free</PlanType>
                    <PlanPrice2 className="plan-price">$0</PlanPrice2>
                  </PlanDetails>
                  {subscription2 === null ? (
                    <CurrentPlanBadge className="current-plan-badge">
                      Current Plan
                    </CurrentPlanBadge>
                  ) : (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={downgrade1}
                    >
                      Select Plan
                    </CurrentPlanBadge>
                  )}
                </Header>
                <FeaturesList className="features-list">
                  <FeatureItem className="feature-item">5 songs</FeatureItem>
                  <FeatureItem className="feature-item">
                    No PDF Downloads
                  </FeatureItem>
                </FeaturesList>
              </Card>

              {/* <PlanCard1 subscription={subscription2} /> */}

              <Card className="plan-card">
                <Header1 className="plan-header">
                  <PlanDetails className="plan-details">
                    <PlanType className="plan-type">Yearly</PlanType>
                    <PlanPrice2 className="plan-price">$79.99</PlanPrice2>
                  </PlanDetails>
                  {subscription2 === "yearly" ? (
                    <CurrentPlanBadge className="current-plan-badge">
                      Current Plan
                    </CurrentPlanBadge>
                  ) : (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub}
                    >
                      Change Plan
                    </CurrentPlanBadge>
                  )}
                </Header1>
                <FeaturesList className="features-list">
                  <FeatureItem1 className="feature-item">
                    Unlimited Songs
                  </FeatureItem1>
                  <FeatureItem1 className="feature-item">
                    Unlimited PDF Downloads
                  </FeatureItem1>
                </FeaturesList>
              </Card>

              {/* <PlanCard2 subscription={subscription2} /> */}

              <Card className="plan-card">
                <Header2 className="plan-header">
                  <PlanDetails className="plan-details">
                    <PlanType className="plan-type">Monthly</PlanType>
                    <PlanPrice2 className="plan-price">$9.99</PlanPrice2>
                  </PlanDetails>

                  {subscription2 === "monthly" ? (
                    <CurrentPlanBadge className="current-plan-badge">
                      Current Plan
                    </CurrentPlanBadge>
                  ) : (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub1}
                    >
                      Change Plan
                    </CurrentPlanBadge>
                  )}
                </Header2>
                <FeaturesList className="features-list">
                  <FeatureItem1 className="feature-item">
                    &nbsp;&nbsp;50 Songs&nbsp;&nbsp;
                  </FeatureItem1>
                  <FeatureItem1 className="feature-item">
                    &nbsp;&nbsp;&nbsp; 50 PDF Downloads&nbsp;&nbsp;&nbsp;
                  </FeatureItem1>
                </FeaturesList>
              </Card>
            </PlanCardsContainer>
          </Section1>
        </PlansWrapper>
        {down && (
          <PopupOverlay>
            {status === "active" ? (
              <Downgrade
                onClose={() => setDown(false)}
                formattedDate={formattedDate}
              />
            ) : (
              <Downgrade1
                onClose={() => setDown(false)}
                formattedDate={formattedDate}
              />
            )}
          </PopupOverlay>
        )}

        {showcard && (
          <PopupOverlay>
            <Setcard onClose={() => setshowcard(false)} />
          </PopupOverlay>
        )}
        {showaftercard && (
          <PopupOverlay>
            <After_set_cards onClose={() => setshowaftercard(false)} />
          </PopupOverlay>
        )}
        {showyearcard && (
          <PopupOverlay>
            <Downgrade2
              onClose={() => setshowyearcard(false)}
              formattedDate={formattedDate}
            />
          </PopupOverlay>
        )}
      </PlansSection>
    </MainContainer>
  );
}

function Downgrade({ onClose, formattedDate }) {
  const token = getToken();
  const navigate = useNavigate();
  // const ActionButton = ({ children, primary, secondary, ...props }) => {
  //   return (
  //     <StyledButton primary={primary} secondary={secondary} {...props}>
  //       {children}
  //     </StyledButton>
  //   );
  // };

  const del_sub = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/subscription/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  return (
    //   <Modal>
    //     <HeaderContainer1>
    //       <WarningText>Warning</WarningText>
    //       {/* <CloseButton onClick={onClose}> */}
    //       <CloseIcon onClick={onClose} loading="lazy" src={white_close} alt="Close" />
    //       {/* </CloseButton> */}
    //     </HeaderContainer1>

    //       <Content1>
    //         The auto-renewal of your subscription will be cancelled. However, your subscription is valid until {formattedDate}.
    //       </Content1>
    //       <ActionButton primary onClick={del_sub}>Confirm</ActionButton>
    //       <ActionButton secondary onClick={onClose}>Cancel</ActionButton>

    // </Modal>

    <Modal>
      <ModalContainer1>
        <Header9>
          <Title2>Are you sure you want to cancel auto-renewal?</Title2>
          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header9>
        <Message>
          Your subscription will remain active until {formattedDate}. After this
          date, you'll need to manually renew your subscription to continue
          using our service.
        </Message>

        <ButtonContainer>
          <PrimaryButton onClick={onClose}>No, keep auto-renewal</PrimaryButton>
          <SecondaryButton onClick={del_sub}>
            Yes, cancel auto-renewal
          </SecondaryButton>
        </ButtonContainer>
      </ModalContainer1>
    </Modal>
  );
}

function Downgrade2({ onClose, formattedDate }) {
  return (
    <Modal1>
      <ModalContainer1>
        <Header9>
          <Title2>Annual Plan Active</Title2>
          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header9>
        <Message>
          Your current subscription is an annual plan that expires on <br></br>
          {formattedDate}. To switch to a monthly plan, please<br></br> wait
          until your annual plan expires.
        </Message>

        <ButtonContainer1>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </ButtonContainer1>
      </ModalContainer1>
    </Modal1>
  );
}

function Downgrade1({ onClose, formattedDate }) {
  return (
    <Modal1>
      <ModalContainer1>
        <Header9>
          <Title2>Subscription Cancellation Confirmation</Title2>
          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header9>
        <Message>
          You have already canceled your auto-renewal. Your subscription will
          remain active until {formattedDate}.
        </Message>

        <ButtonContainer1>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </ButtonContainer1>
      </ModalContainer1>
    </Modal1>
  );
}

const PlanCardP = styled.div`
  @media (max-width: 991px) {
    max-width: 80%;
    flex: 1 1 auto; /* Allow the item to adjust height automatically */
  }
`;

const MainContainer = styled.main`
  background-color: #282828;
  flex-direction: column;
  padding-bottom: 24px;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 600px;
  position: relative; /* Added for positioning CloseButton */
  @media (max-width: 991px) {
    width: 90%;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 20px;
  background: none;
  border: none;
  font-size: 34px;
  color: #fff;
  cursor: pointer;
  @media (max-width: 991px) {
    top: 8px;
    left: 150px;
    width: 100%;
  }
`;

const Section = styled.section`
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Article = styled.article`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ImageWrapper = styled.div`
  width: 16%;
  margin-top: 20px;
  @media (max-width: 991px) {
    width: 26%;
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Content = styled.div`
  flex-direction: column;
  width: 100%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    margin-right: 0px;
  }
`;

const Content1 = styled.div`
  flex-direction: row;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    margin-right: 0px;
  }
`;

const Title = styled.h2`
  font: 700 32px/62px Montserrat, sans-serif;
  color: #fff;
  text-align: center;
  margin-top: 8px;
  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 40px;
    text-align: center;
  }
`;

const Title1 = styled.h2`
  font: 700 32px/62px Montserrat, sans-serif;
  color: #ff8c39;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 40px;
    text-align: center;
  }
`;

const Description = styled.p`
  font: 400 16px/26px Poppins, sans-serif;
  color: #fff;
  text-align: center;
  margin-top: 8px;
  @media (max-width: 991px) {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const PlansSection = styled.section`
  background-color: #282828;
  width: 100%;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const PlansWrapper = styled.div`
  display: flex;
  gap: 10px; /* Fixed gap issue */
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Section1 = styled.div`
  align-items: center;
  align-self: center;
  gap: 10px;
  background-color: #282828;
  @media (max-width: 991px) {
    margin-left: 20px;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const Modal = styled.div`
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.16);
  display: flex;
  max-width: 428px;
  max-height: 385px;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  font: 600 18px Poppins, sans-serif;
`;

const Modal1 = styled.div`
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.16);
  display: flex;
  max-width: 428px;
  max-height: 340px;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  font: 600 18px Poppins, sans-serif;
`;
const ModalContainer1 = styled.div`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  width: 100%;
  padding-bottom: 36px;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  align-self: stretch;
  flex: 1;
  border-radius: 4px;
  min-width: 240px;
  width: 100%;
  padding: 17px 30px;
  font: inherit;
  cursor: pointer;
  border: none;
`;
const PrimaryButton = styled(Button)`
  background-color: #0072d5;
  color: #fff;
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: #717171;
  margin-top: 5px;
`;

const Message = styled.p`
  color: #3e3635;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.15px;
  margin-top: 28px;
  margin-left: 15px;
  margin-right: 15px;
`;
const Header9 = styled.header`
  background-color: #0072d5;
  align-self: stretch;
  position: relative;
  display: flex;
  min-height: 64px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  letter-spacing: -0.18px;
  line-height: 23px;
  justify-content: center;
  padding: 19px 10px;
`;
const Title2 = styled.h3`
  z-index: 0;
  width: 350px;
  margin: auto 0;
`;

const Icon2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: start;
  position: absolute;
  z-index: 0;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
  min-height: 128px;
  width: 100%;
  max-width: 296px;
  flex-direction: column;
  justify-content: start;
`;

const ButtonContainer1 = styled.div`
  display: flex;
  margin-top: 18px;
  min-height: 60px;
  width: 100%;
  max-width: 296px;
  flex-direction: column;
  justify-content: start;
`;

export default Pricing2;
