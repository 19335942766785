import React, { useState, useEffect } from 'react';
import styled, { keyframes,css } from 'styled-components';
import { getToken } from "../utils";
import { playlist_image } from "../../assets/images";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { clearToken } from "../utils";
import NewPlaylistForm from "./create_playlist_popup";

function PlaylistForm({ onClose, id1 }) {
    const navigate = useNavigate();
    const token = getToken();

    const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylists, setSelectedPlaylists] = useState([]); // Current selected
    const [checkedPlaylists, setCheckedPlaylists] = useState([]); // Previously selected from url_request
    const [showplaylist_popup4, setshowplaylist_popup4] = useState(false);
    const [loading, setLoading] = useState(true);
    const playlistadded123 = localStorage.getItem("playlistadded123");

    // Fetch playlists and previously selected ones
    useEffect(() => {
        get_playlist();
    }, [id1]);


    useEffect(() => {

      if (playlistadded123) {
  
        get_playlist();
        localStorage.removeItem('playlistadded123');
  
       
  
      }
  
    }, [playlistadded123]);

    const get_playlist = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/playlists/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const playlistsData = response.data.map((item) => ({
                    name: item.name,
                    image: item.song_image_url,
                    tracks: item.total_songs,
                    id: item.playlist_id,
                }));
                setPlaylists(playlistsData);
                url_request(); // Fetch previously selected playlists after getting all playlists
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
                navigate("/");
            }
        }
    };

    const url_request = async () => {
        try {
            const encodedRequestId1 = encodeURIComponent(id1);
            const response = await axios.get(`${config.apiUrl}/url_requests/${encodedRequestId1}/playlists`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const previouslySelectedPlaylists = response.data.playlists.map(id => id);
                setCheckedPlaylists(previouslySelectedPlaylists);
                setSelectedPlaylists(previouslySelectedPlaylists); // Set initial state for selected checkboxes
                setLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
                navigate("/");
            }
        }
    };

    // Handle checkbox changes
    const handleCheckboxChange = (id) => {
        setSelectedPlaylists((prevSelected) => {
            if (prevSelected.includes(id)) {
                // Remove if already selected
                return prevSelected.filter((playlistId) => playlistId !== id);
            } else {
                // Add if not selected
                return [...prevSelected, id];
            }
        });
    };

    const handleyouClick_playlist3 = () => {
     
      setshowplaylist_popup4(true);
      
    };
  
    const handleyouClick_playlist4 = () => {

      setshowplaylist_popup4(false);
    };

    const save_playlist = async () => {
      setLoading(true);
        const newSelections = selectedPlaylists.filter(id => !checkedPlaylists.includes(id)); 
        const removedSelections = checkedPlaylists.filter(id => !selectedPlaylists.includes(id)); 

        const encodedRequestId1 = encodeURIComponent(id1);

        
        if (newSelections.length > 0) {
            try {
                await axios.post(`${config.apiUrl}/url_requests/${encodedRequestId1}/add_to_playlists`, 
                    { "playlists": newSelections }, 
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    clearToken();
                    navigate("/");
                }
            }
        }

        
        if (removedSelections.length > 0) {
            try {
                await axios.post(`${config.apiUrl}/url_requests/${encodedRequestId1}/remove_from_playlists`, 
                    { "playlists": removedSelections }, 
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    clearToken();
                    navigate("/");
                }
            }
        }
        localStorage.setItem("playlist_added_successfully", encodedRequestId1);
        onClose();
    };

    return (
      <>
          {!showplaylist_popup4 && (
              <FormContainer>
                  <Overlay loading={loading}>
                      <Spinner />
                  </Overlay>
                  <Form>
                      <HeaderContainer>
                          <Title>Add to Playlist</Title>
                          <CloseButton onClick={onClose}>×</CloseButton>
                      </HeaderContainer>

                      <Layout>
                          <Title1>All Playlist</Title1>
                          <ButtonContainer1>
                              <Button1 onClick={handleyouClick_playlist3}>Create New Playlist</Button1>
                          </ButtonContainer1>
                      </Layout>

                      <PlaylistContainer>
                          {playlists.map((playlist) => (
                              <PlaylistItem key={playlist.id}>
                                  <PlaylistImage src={playlist.image ? playlist.image : playlist_image} alt={playlist.name} />
                                  <Play>
                                      <PlaylistName>{playlist.name}</PlaylistName>
                                      <Playlisttracks>{playlist.tracks} Tracks</Playlisttracks>
                                  </Play>
                                  <PlaylistCheckbox
                                      type="checkbox"
                                      checked={selectedPlaylists.includes(playlist.id)}
                                      onChange={() => handleCheckboxChange(playlist.id)}
                                  />
                              </PlaylistItem>
                          ))}
                      </PlaylistContainer>

                      <ButtonContainer>
                          <Button onClick={save_playlist}>Save</Button>
                      </ButtonContainer>
                  </Form>
              </FormContainer>
          )}

          {showplaylist_popup4 && (
              <PopupOverlay>
                  <NewPlaylistForm onClose={handleyouClick_playlist4} />
              </PopupOverlay>
          )}
      </>
  );
}

// Styled components remain the same
const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
z-index: 999; /* Ensure it's above other content */
display: ${props => (props.loading ? 'flex' : 'none')};
flex-direction: column;
justify-content: center;
align-items: center;
color: white;
font-size: 1.5em;
`;

 const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;


 const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;
const FormContainer = styled.div`
  width: 400px;
  padding: 20px;
  background-color: #202020;
  border-radius: 10px;
  height:550px;
   @media (max-width: 991px) {
   width: 80%;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;







const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;  /* Center content horizontally */
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative; /* To keep the close button aligned properly */
`;

const Title = styled.div`
  color: white;
  margin: 0;
   font: 600 26px/1.3 Poppins, sans-serif;
    @media (max-width: 991px) {
   font: 600 20px/1.3 Poppins, sans-serif;
  }
`;


const CloseButton = styled.button`
  position: absolute;
  right: 0;  /* Position the close button to the right */
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
`;

const PlaylistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  max-height: 350px; /* Adjust height for the container */
  overflow-y: auto;  /* Enable vertical scrolling */
  padding: 4px;
  min-height: 350px;
`;

const PlaylistItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures left and right alignment */
  padding: 10px 0;
  border-radius: 10px;
  color: white;
  width: 100%;  /* Ensure the item takes full width */
`;

const Play = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;  /* Allow this to take as much width as possible */
  margin-left: 10px;  /* Add space between image and text */
`;
const PlaylistName = styled.div`
  flex-grow: 1;
  font: 600 16px Poppins, sans-serif;
  margin-left: 10px;
  margin-bottom:5px;
`;

const Playlisttracks = styled.div`
  flex-grow: 1;
   font: 400 14px/100% Poppins, sans-serif;
  margin-left: 10px;
`;

const PlaylistCheckbox = styled.input`
  margin-right: 10px;
  width: 18px;   /* Set the width of the checkbox */
  height: 18px;  /* Set the height of the checkbox */
  transform: scale(1.5);  /* Scale the checkbox size up by 1.5 times */
  cursor: pointer;  /* Optional: Change cursor to pointer when hovering */
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom:30px;
   margin-top: 15px;
`;

const Button = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
 
  min-height: 60px;
 
  width:400px;
  color: #fff;
  text-align: center;
  padding: 0 0px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }&:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
     @media (max-width: 991px) {
   min-height: 50px;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
    margin-bottom: 10px;
`;

const Title1 = styled.h3`
  color: white;
  margin: 0;
 
`;

const ButtonContainer1 = styled.div`

  margin-top: 15px;
`;

const Button1 = styled.button`
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


const PlaylistImage = styled.img`
  width: 77px;
  height: 64px;
  border-radius: 10px;
  object-fit: cover;
`;

export default PlaylistForm;
