import React from "react";
import styled from "styled-components";
// import { fourth_section } from '../../assets/images';
import useWindowWidth from "../global"
import image_url from "../../config"

const Fourth_Section = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  return (
    <StyledHeroSection>
      <BackgroundImage 
        loading="lazy" 
        src="https://jammin-stage.s3.amazonaws.com/images/fourth_section.svg"
        alt="Background" 
      />
      <ContentWrapper>
        <Heading>
          <span>Ready</span> <span>to</span> <span>Jam</span>
        </Heading>
        {isMobile ? (
           <Description>
           We believe music is a powerful force that connects us all. jammin.ai is your platform to explore, create, and share your love of music, whether you're a seasoned musician or just starting out. Join our thriving community of music enthusiasts and let the jam session begin!
         </Description>
       ) : ( 
        <Description>
        We believe music is a powerful force that connects us all. jammin.ai is your platform to explore, create, and share your<br></br> love of music, whether you're a seasoned musician or just starting out. Join our thriving community of music<br></br> enthusiasts and let the jam session begin!
      </Description>
          )}
       
      </ContentWrapper>
    </StyledHeroSection>
  );
};

const StyledHeroSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;    
  height: 576px;
  overflow-x: hidden; 
  overflow-y: hidden; 
  @media (max-width: 991px) {
    padding:0px;
    width: 100%; 
    height: 240px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
   @media (max-width: 991px) {
    
    height: 100%;
  }
`;

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  width: 100%; /* Adjust as needed */
  height:100%;
  
  
  margin: auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 10px;
    margin-right:70px;
  }
`;

const Heading = styled.h1`
  letter-spacing: -0.44px;
  text-transform: uppercase;
  color: white;
  font: 900 44px/1.2 Poppins, sans-serif;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
    font: 900 20px/1.2 Poppins, sans-serif;
  }
`;

const Description = styled.p`
  letter-spacing: -0.2px;
  margin-top: 4px;
  font: 600 20px/28px Open Sans, sans-serif;
  color: white;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
     font-size: 14px;
     line-height: 20px;
     font-weight: 600;
     margin-right:20px;
  }
`;

export default Fourth_Section;
