import React, { useState } from 'react';
import styled from "styled-components";
import {
    getToken,
} from "../utils";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { clearToken } from "../utils";


function EditPlaylistForm({ onClose, name, id1 }) {
    const navigate = useNavigate();
    const token = getToken();
  
    const [playlistTitle, setPlaylistTitle] = useState(name || ""); // Initialize with the current playlist name
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display
  
    const Save = async (title) => {
      try {
        const encodedRequestId1 = encodeURIComponent(id1);
        const response = await axios.patch(
          `${config.apiUrl}/playlists/${encodedRequestId1}`,
          {
            name: title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 200) {
          localStorage.setItem("edit_playlist", response.status);
          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };
  
    const handleCreateClick = (event) => {
        event.preventDefault();
      // Call the Save function and pass the playlistTitle value
      if (playlistTitle.trim() !== "") {
        Save(playlistTitle);
      } else {
        setErrormsg("Title cannot be empty"); // Handle empty input
      }
    };
  
    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title>Edit Playlist Name</Title>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>
  
          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="playlistTitle"
                type="text"
                placeholder="Enter playlist title"
                aria-label="Enter playlist title"
                value={playlistTitle}
                onChange={(e) => setPlaylistTitle(e.target.value)} 
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>} {/* Show error message only if present */}
          </InputContainer>
  
          <ButtonContainer>
            <Button onClick={handleCreateClick}>Save</Button>
          </ButtonContainer>
        </Form>
      </FormContainer>
    );
  }

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
    right: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
    @media (max-width: 991px) {
    margin-left:20px;
    margin-right:20px;
  }
`;

const InputContainer = styled.section`
  align-self: center;
  display: flex;
  margin-top: 32px;
  width: 100%;
  width: 396px;
  flex-direction: column;
   @media (max-width: 991px) {
    margin-left:20px;
    margin-right:20px;
  }
`;

const Label = styled.label`
  color: #fff;
  font: 600 16px Inter, sans-serif;
    @media (max-width: 991px) {
    margin-left:20px;
    margin-right:20px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  min-height: 56px;
    @media (max-width: 991px) {
    margin-left:20px;
    margin-right:20px;
  }
`;

const Input = styled.input`
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 17px 16px;
  border: 1px solid #ececec;
  color: #414141;
  font: 400 13px/32px Poppins, sans-serif;
`;

const Button = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 55px;
  min-height: 60px;
  margin-bottom:40px;
  width:400px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }&:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title = styled.h2`
  z-index: 0;
  margin: 0;
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;

const FormContainer = styled.main`
 
  border-radius: 12px;
 
  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left:20px;
    margin-right:20px;
  }
`;

const Form = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export default EditPlaylistForm;