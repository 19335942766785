import React, { useState } from "react";
import styled from "styled-components";
import {

  new_logo,

    lg_white,
} from "../../assets/images";
import GoogleSignIn from '../../google';


function Pre_nav_offline() {






    const initials = 'U';
    const img_url = " ";

    return (
        <Header>
            <Nav1>
                <Logo
                    loading="lazy"
                    src={new_logo}
                    alt="Logo"

                />

               

            </Nav1>
            <Nav2>
                <div>
                    <GoogleSignIn />
                </div>
            </Nav2>


        </Header>
    );
}

const Header = styled.header`
  background-color: #312f2f;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0;
    background-color: #312f2f;
    flex-direction: row;
  }
`;

const Nav1 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0px;
  @media (max-width: 991px) {
    
    flex-direction: row;
  }
`;
const Nav2 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right:20px;
  gap: 0px;
  @media (max-width: 991px) {
    
    flex-direction: row;
  }
`;

const Logo = styled.img`
  object-position: center;
  width: 138px;
  fill: #fff;
  max-width: 100%;
  margin-left: 20px;
  padding: 0;
  cursor: pointer;
  @media (max-width: 991px) {
     margin-left: 10px;
  }
`;

const Logo2 = styled.img`
  display: none;
  @media (max-width: 991px) {
    display: block;
    object-fit: auto;
    object-position: center;
    width: 108px;
    fill: #fff;
    max-width: 100%;
    margin-top: 20px;
  }
`;

const Logo1 = styled.img`
  flex-direction: row;
  margin-left: 6px;
  fill: #fff;
`;

const UserIcon = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f6b704;
  display: flex;
  width: 48px;
  height: 48px;
  margin-top: 18px;
  margin-right: 15px;
  color: #fff;
  cursor: pointer;
  font: 600 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 15px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export default Pre_nav_offline;
