import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import {
  billing_cancel_plan,
  billing_upgrade_plan,
  download_icon,
  orange_tick,
  white_close,
} from "../../assets/images";
import config from "../../config";
import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import useSubscription from "../user-page/subscription";
import {
  clearToken,
  getToken,
  getsubscription,
  getsubscription_date,
  getsubscription_status,
  getsubscription_type
} from "../utils";

const StyledCell = styled.div`
  white-space: pre-wrap;
  flex: 1;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 143%;
  margin: auto 0;
  font-family: Poppins, sans-serif;
  text-align: center;
  &:first-child {
    text-align: left;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

function Mybilling() {
  const token = getToken();
  const date = getsubscription_date();
  const subscription = getsubscription();

  const subscription_type = getsubscription_type();
  let types;

  if (subscription_type == "monthly") {
    types = "Monthly Subscription";
  } else if (subscription_type == "yearly") {
    types = "Yearly Subscription";
  }

  const subscription1 = useSubscription();
  const subscription2 = getsubscription_status();

  const [loading, setLoading] = useState(false);
  const [all_bill, setall_bill] = useState([]);
  const navigate = useNavigate();

  if (token == null) {
    navigate("/");
  }
  const [selectedNavItem, setSelectedNavItem] = useState("/mybilling");
  const amount =
  subscription_type === "monthly"
      ? "$9.99 per month"
      : "$79.99 per year";

  const [down, setDown] = useState(false);

  const downgrade1 = () => {
    setDown(true);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/subscription/billing`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const records = response.data; // No need for .records as data is an array

          const mappedPlaylists = records.map((record) => {
            let price1;

            // Determine the price based on the subscription price
            if (record.subscription_price === 999) {
              price1 = "9.99";
            } else if (record.subscription_price === 7999) {
              price1 = "79.99";
            } else {
              price1 = (record.subscription_price / 100).toFixed(2); // Convert to proper price format
            }

            const date1 = new Date(record.paid_at).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            });

            return {
              date: date1,
              type: record.subscription_type,
              price: price1,
              subscription_id: record.invoice_pdf,
              receipt: record.receipt_url,
            };
          });

          setall_bill(mappedPlaylists);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    };

    fetchPlaylists();
  }, []);

  const calculateRenewalDate = (subscribedAt, subscriptionType) => {
    const subscribedDate = new Date(subscribedAt);

    if (subscriptionType === "Monthly subscription") {
      const currentMonth = subscribedDate.getMonth();
      const newMonth = currentMonth + 1;

      subscribedDate.setMonth(newMonth);
    } else {
      // Add one year
      subscribedDate.setFullYear(subscribedDate.getFullYear() + 1);
    }

    const options = { day: "numeric", month: "long", year: "numeric" };
    return subscribedDate.toLocaleDateString(undefined, options);
  };

  const renewalDate = calculateRenewalDate(date, subscription);

  // const upgrade = async () => {
  //   navigate("/");
  //   setTimeout(() => {
  //     const element = document.getElementById("pricing-section");
  //     if (element) {
  //       element.scrollIntoView({ behavior: "smooth" });
  //     } else {
  //       // Handle case where element is not found
  //     }
  //   }, 100);
  // };

  const upgrade = async () => { try {
    setLoading(true);
    const response = await axios.post(
      `${config.apiUrl}/subscription/`,
      {
        subscription_type: "monthly",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      const checkoutUrl = response.data.checkout_url;
     
      window.open(checkoutUrl);
      setLoading(false);
      
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      clearToken();
      navigate("/");
    } 
   
  }

    

  };

  const view_subscription = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/subscription_history/${id}/pdf`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const invoiceUrl = response.data.invoice_url;
        window.open(invoiceUrl, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const download_subscription = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/subscription_history/${id}/pdf`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const invoiceUrl = response.data.invoice_url;
        window.location.href = invoiceUrl; // Redirects to the invoice URL within the same tab
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const download_subscription1 = async (id) => {
    window.location.href = id;
  };

  const download_receipt = async (id) => {
    window.location.href = id;
  };

  return (
    <PageWrapper>
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <ContentWrapper>
        <Sidebar>
          <NavItem onClick={() => navigate("/myprofile")}>My Profile</NavItem>
          <NavItem selected={selectedNavItem === "/mybilling"}>
            Billing and Subscription
          </NavItem>
          <NavItem onClick={() => navigate("/settings")}>Settings</NavItem>
        </Sidebar>
        <MainContent>
          <div className="flex flex-col">
            <div
              className="w-full text-2xl font-bold tracking-tight leading-9 text-white max-md:max-w-full"
              style={{ fontSize: "26px" }}
            >
              Active Plan - {types}
            </div>
            {subscription1 == null ? (
              <>
                <div
                  className="mt-2 w-full text-base font-medium tracking-normal leading-6 text-white max-md:max-w-full"
                  style={{ fontSize: "16px", marginTop: "10px" }}
                >
                  You don't have any Active Plans
                </div>
              </>
            ) : (
              <>
                <div
                  className="mt-1 w-full text-base font-medium tracking-normal leading-6 text-white max-md:max-w-full"
                  style={{ fontSize: "16px", marginTop: "10px" }}
                >
                  You’re currently on a {subscription} Plan at{" "}
                  <span className="font-bold">{amount}</span> which will renew
                  on <span className="font-bold">{renewalDate}</span>
                </div>
              </>
            )}

            <div
              className="w-full text-xl font-bold tracking-normal leading-7 text-white max-md:max-w-full"
              style={{ marginTop: "2rem", fontSize: "20px" }}
            >
              Included in your Plan:
            </div>
            {/* {subscription1 == null ? (
              <>
                <div className="mt-2 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full"
                  style={{ marginTop: "10px" }}>
                  <img src={orange_tick} alt="Tick" style={{ display: 'inline', marginRight: '8px',width:'17px',height:'17px' }} />
                  3 Songs
                </div>
                <div className="mt-1 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full"
                  style={{ marginTop: "10px" }}>
                    <img src={orange_tick} alt="Tick" style={{ display: 'inline', marginRight: '8px',width:'17px',height:'17px'}} />
                  No PDF Downloads
                </div>
              </>
            ) : (
              <>
                <div className="mt-2 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full"
                  style={{ marginTop: "10px" }}>
                    <img src={orange_tick} alt="Tick" style={{ display: 'inline', marginRight: '8px' ,width:'15px',height:'15px'}} />
                  Unlimited Songs
                </div>
                <div className="mt-1 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full"
                  style={{ marginTop: "10px" }}>
                    <img src={orange_tick} alt="Tick" style={{ display: 'inline', marginRight: '8px',width:'15px',height:'15px' }} />
                  Unlimited PDF Downloads
                </div>
              </>
            )} */}

            {subscription1 == null ? (
              <>
                <div className="mt-2 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full">
                  <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "6px",
                      width: "17px",
                      height: "17px",
                    }}
                  />
                  5 Songs
                </div>
                <div className="mt-1 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full">
                  <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "6px",
                      width: "17px",
                      height: "17px",
                    }}
                  />
                  No PDF Downloads
                </div>
              </>
            ) : null}

            {subscription1 === "monthly" ? (
              <>
                <div className="mt-2 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full">
                  <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "6px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  50 Songs per Month
                </div>
                <div className="mt-1 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full">
                  <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "6px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  50 PDF Downloads
                </div>
              </>
            ) : null}

            {subscription1 === "yearly" ? (
              <>
                <div className="mt-2 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full">
                  <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "6px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  Unlimited Songs
                </div>
                <div className="mt-1 w-full text-sm font-semibold tracking-normal leading-5 text-white max-md:max-w-full">
                  <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "6px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  Unlimited PDF Downloads
                </div>
              </>
            ) : null}

            <ProfileSection>
            {subscription_type !== "yearly" ? (
              <Button src={billing_upgrade_plan} onClick={upgrade}></Button>
              ) : null}
              

              {subscription1 && subscription2 !== "cancelled" ? (
              // {subscription1 !== null && subscription2 !=="cancelled" ? (
                <Button1
                  src={billing_cancel_plan}
                  onClick={downgrade1}
                ></Button1>
              ) : null}
            </ProfileSection>
            <div
              className="flex gap-2.5 justify-between px-5 mt-11 w-full text-white max-md:flex-wrap max-md:mt-10 max-md:max-w-full"
              style={{ marginTop: "2rem" }}
            >
              <div className="my-auto text-xl font-bold tracking-normal leading-7">
                Billing History
              </div>
            </div>
            <ResponsiveWrapper>
              <StyledTable>
                <StyledHeader1>
                  <tr>
                    <StyledHeaderCell>Date</StyledHeaderCell>

                    <StyledHeaderCell>Plans</StyledHeaderCell>
                    <StyledHeaderCell>Amount</StyledHeaderCell>
                    <StyledHeaderCell>Invoice Download</StyledHeaderCell>
                    <StyledHeaderCell>Receipt Download</StyledHeaderCell>
                  </tr>
                </StyledHeader1>
                <StyledBody>
                  {all_bill.length ? (
                    all_bill.map((bill, index) => (
                      <StyledRow1 key={index}>
                        <StyledCell1>{bill.date}</StyledCell1>

                        <StyledCell1>{bill.type}</StyledCell1>
                        <StyledCell1>{bill.price}</StyledCell1>
                        <StyledCell1>
                          <ActionButton
                            src={download_icon}
                            onClick={() =>
                              download_subscription1(bill.subscription_id)
                            }
                          />
                        </StyledCell1>
                        <StyledCell1>
                          <ActionButton
                            src={download_icon}
                            onClick={() => download_receipt(bill.receipt)}
                          />
                        </StyledCell1>
                      </StyledRow1>
                    ))
                  ) : (
                    <tr>
                      <StyledCell colSpan={5}>
                        No billing history found.
                      </StyledCell>
                    </tr>
                  )}
                </StyledBody>
              </StyledTable>
            </ResponsiveWrapper>
          </div>
        </MainContent>
        {down && (
          <PopupOverlay>
            <Downgrade onClose={() => setDown(false)} />
          </PopupOverlay>
        )}
      </ContentWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  );
}

function Downgrade({ onClose }) {
  const token = getToken();
  const navigate = useNavigate();
  const ActionButton = ({ children, primary, secondary, ...props }) => {
    return (
      <StyledButton1 primary={primary} secondary={secondary} {...props}>
        {children}
      </StyledButton1>
    );
  };

  const del_sub = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/subscription/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  return (
    <Modal>
      <HeaderContainer1>
        <WarningText>Warning</WarningText>
        {/* <CloseButton onClick={onClose}> */}
        <CloseIcon
          onClick={onClose}
          loading="lazy"
          src={white_close}
          alt="Close"
        />
        {/* </CloseButton> */}
      </HeaderContainer1>
      <Content1>
        By downgrading you will lose all your saved songs. Make sure to download
        any saved songs before you continue
      </Content1>
      <ActionButton primary onClick={del_sub}>
        Confirm
      </ActionButton>
      <ActionButton secondary onClick={onClose}>
        Cancel
      </ActionButton>
    </Modal>
  );
}

const StyledAmountCell = styled(StyledCell)`
  text-align: center;
`;

const StyledBody = styled.tbody`
  font-size: 14px;
  color: #3a4857;
  font-weight: 500;
  text-align: center;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledCell1 = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  color: #fff;

  &:last-child {
    text-align: center;
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledRow1 = styled.tr`
  border-bottom: 1px solid #ddd;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledActionCell = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;

  justify-content: center;
  padding: 12px 64px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
const ActionButton = styled.img`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  gap: 20px;
  img {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
  }
`;
const Button = styled.img`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
`;

const ResponsiveWrapper = styled.div`
  overflow-x: auto;
  margin-top: 10px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;
const Button1 = styled.img`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
`;

const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height: 300px;
  padding: 0 0 0px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: -0.14px;
  margin-top: 37px;
  font: 500 14px/21px Poppins, sans-serif;
`;

const StyledButton = styled.button`
  font: 400 18px/21px Poppins, sans-serif;
  border-radius: 4px;
  align-self: center;
  white-space: nowrap;
  padding: 17px 30px;
  width: 50%;
  margin-top: ${(props) => (props.secondary ? "10px" : "24px")};
  background-color: ${(props) => (props.primary ? "#428bca" : "transparent")};
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  border: none;
  cursor: pointer;
`;

const MessageText = styled.p`
  letter-spacing: -0.16px;
  margin-top: 24px;
  font: 500 16px/24px Poppins, sans-serif;
`;

const HeaderContainer1 = styled.header`
  display: flex;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  gap: 0px;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const WarningText = styled.h2`
  flex-grow: 1;
  flex-basis: auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;

  width: 24px;
  margin-top: 20px;
  align-self: flex-start;
  cursor: pointer;
  margin-right: 5px;
`;

const StyledRow = styled.div`
  justify-content: space-between;
  border-color: rgba(255, 255, 255, 1);
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  display: flex;
  gap: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid #fff; // Add a white line between rows
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const StyledHeader1 = styled.thead`
  background-color: #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-color:#000 @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
  border: 1px solid #fff;
  color:#fff @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledHeaderCell = styled.th`
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  color: #0e0e0e;

  &:last-child {
    text-align: center;
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledHeaderCell1 = styled.div`
  font-family: Poppins, sans-serif;
  white-space: nowrap;
  color: #fff;
  padding: 12px 16px;
  flex: 1;
  text-align: center; // Centering the header text
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: center;
  }
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyledHeader = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 8px;
  font-size: 14px;
  color: var(--material-theme-ref-secondary-secondary30, #3a4857);
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 143%;
  padding: 0 0px;
  @media (max-width: 991px) {
    overflow-x: auto;
  }
`;

const StyledButton1 = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  align-self: center;
  white-space: nowrap;
  padding: 17px 30px;
  width: 50%;
  margin-top: ${(props) => (props.secondary ? "12px" : "12px")};
  background-color: ${(props) => (props.primary ? "#428bca" : "transparent")};
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  border: none;
  cursor: pointer;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const CancelSubscriptionButton = styled.div`
  padding: 8px 12px;
  font-size: 12px;
  color: white;
  background-color: #2563eb;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #1d4ed8;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 200px;
  background-color: #312f2f;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: row;
    padding: 0px;
    display: flex;
  }
`;

const NavItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "white" : "white")};
  background-color: ${(props) => (props.selected ? "#3b82f6" : "transparent")};
  padding: 10px;
  border-radius: 0px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  &:hover {
 
`;

const MainContent = styled.div`
  flex: 2;
  position: relative;
  overflow: auto;
  background-color: #1e1e1e;
  padding: 20px;
  color: #ffffff;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const FooterWrapper = styled.div`
  background-color: #312f2f;
  color: white;
  padding: 20px;
`;

export default Mybilling;
