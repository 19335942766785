import React from "react";
import styled from "styled-components";
import Pre_nav from "../navigation/premium_nav";
import Pre_nav_offline from "../navigation/pre_nav_offline";
import Footer from "../footer_lan";
import { getToken } from "../utils";
import { useNavigate } from "react-router-dom";


function PrivacyPolicy() {
  const token = getToken();

  const navigate = useNavigate();
  const contact = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };
  return (
    <MainContainer>
      {token?<Pre_nav /> : <Pre_nav_offline />}
      <ContentWrapper>
      <FAQContainer>
        <Section>
          <PolicyTitle>Privacy Policy for Jammin.ai</PolicyTitle>
          <Divider />

          <SectionText>
            Thank you for using the services provided and operated by Jammin.ai, Inc. (including its affiliates, agents, and service providers, collectively, ‘Jammin.ai’, ‘we’, ‘us’, or ‘our’).<br/>
            <br/>
            Jammin.ai effortlessly transforms any song into a playable experience. Our AI instantly crafts precise guitar chords and accurate lyrics for songs in any language, turning your favorite tunes into your personal jam session. No more struggling with complex chords and lyrics. With Jammin.ai, the music is always at your fingertips.
            </SectionText>
        </Section>
        <Section>
          <SectionTitle>What Information We Collect:</SectionTitle>
          <SectionText>
            We collect two main types of information:
            <Order>
              <List><strong>Personal Information:</strong> This helps us identify you and provide our services. It may include your 
              <Order>
                <List>Name</List>
                <List>Contact information(Email),</List>
                <List>Profile information (optional)</List>
                <List>Payment information (handled securely by Stripe-see their privacy policy here).</List>
              </Order>
              </List>
              <List><strong>Non-Personal Information:</strong> This anonymized data helps us improve Jammin.ai. It may include 
              <Order>
                <List>device information(IP address,browser type, operating syster)</List>
                <List>website usage data(pages viewed ,time spent)</List> 
                <List>Cookies and similar technologies (for user experoemce and analytics)</List>
                <List>Location information (optional for location based features-controllable through device settings)</List>
              </Order>
              </List>
            </Order>
          </SectionText>
        </Section>
        <Section>
          <SectionTitle>Social Media Logins:</SectionTitle>
          <SectionText>
            You can log in with social media accounts (e.g., Google or Apple) for a streamlined experience. The information we receive depends on the platform and may include your name, email, and profile picture. We only use this information as described within the app and as communicated to you.
          </SectionText>
        </Section>
        <Section>
          <SectionTitle>How We Use Your Information:</SectionTitle>
          <SectionText>
            We use your information
            <Order>
            <List>Create and manage your account</List>
            <List>Process payments and provide our services</List>
            <List>improve Jammin.ai and personalize your experience</List>
            <List>Communicate with you (e.g.,for updates or support)</List>
            <List>Analyze trends and improve our platform</List>
            <List>Comply with legal obligations</List>
            </Order>
          </SectionText>
        </Section>
        <Section>
          <SectionTitle>Information Sharing and Disclosure:</SectionTitle>
          <SectionText>
            We may share your information under limited circumstances, such as with 
            <Order>
              <List>Stripe for payment processing</List>
              <List>Third-party service providers who assist in operation our platform(e.g., analytics)</List>
              <List>Law enforcement Or other authorities as required by law.</List>
            </Order>
          </SectionText>
        </Section>
        <Section>
          <SectionTitle>Data Retention and Your Choices:</SectionTitle>
          <SectionText>
            We retain your information only as long as necessary to fulfill the purposes outlined in this policy and to comply with legal obligations. You have control over your information and can:
            <Order>
              <List>Access and update your information through account settings</List>
              <List>Request deletion of your information (subject to legal requirements)</List>
              <List> Opt-out of promotional communications</List>
              <List>Manage cookie preferences through your browser settings</List>
            </Order>
          </SectionText>
        </Section>
        <Section>
          <SectionTitle>Security, Updates, and Contact Us:</SectionTitle>
          <SectionText>
            We take security seriously and implement industry-standard measures to protect your information. This policy may be updated periodically. Please review it regularly for changes. For questions, contact us at <EmailLink onClick={contact}>contact us</EmailLink>.
          </SectionText>
        </Section>
        <Section>
          <SectionText>Thank you for trusting Jammin.ai with your musical journey!</SectionText>
          <SectionText>
            Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request a review, update, or delete your personal information, please email us at <EmailLink onClick={contact}>contact us</EmailLink> We will respond to your request within 7 days.
          </SectionText>
        </Section>
        </FAQContainer>
      </ContentWrapper>
      <Footer />
    </MainContainer>
  );
}

// Styled components for the Privacy Policy page

const MainContainer = styled.main`
 flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  width: 100%;
`;

const Order=styled.ul`

`;

const List =styled.li`

`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom:30px;
  box-sizing: border-box; 

`;
const FAQContainer = styled.section`
  display: flex;
  max-width: 900px;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.16px;
  padding: 0 20px;
  margin: 20px 0; /* Optional: to add some space around the FAQ section */
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
`;
const PolicyTitle = styled.h1`
  font-size: 32px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
`;

const SectionText = styled.p`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #d4d4d4;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify; /* Justify text */
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
  }
  li {
    margin-bottom: 10px;
  }
`;
const Divider = styled.hr`
  margin-top: 7px;
  width: 100%;
  border: 1px solid #7d7d7d;
`;
const EmailLink = styled.a`
  cursor:pointer;
  font-weight: 600;
  color: #428bca;
  text-decoration: none;
`;

export default PrivacyPolicy;
