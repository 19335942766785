import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { about_background } from '../../assets/images';
import Pre_nav from "../navigation/premium_nav";
import Pre_nav_offline from "../navigation/pre_nav_offline";
import Second_section from "./second_section";
import Third_section from "./third_section";
import Fourth_Section from "./fourth_section";
import Fifth_Section from "./fifth_section";
import Sixth_Section from "./sixth_section";
import Footer from "../footer_lan";
import { getToken } from "../utils";
import useWindowWidth from "../global"


function About_us(){
  const token =getToken();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  
  
    return (
        <div>
      
        {token ? (
        <Pre_nav />
      ) : (
        <Pre_nav_offline />
      )}
       {isMobile ? (  <ContentWrapper_mob/>  ) : (  <ContentWrapper/> )}
        
        <Second_section/>
        <Third_section/> 
         <Fourth_Section/>
        <Fifth_Section/>
        <Sixth_Section/>
        <Footer/>
        </div>
    );
} 




const ContentWrapper = () => {
 
    return (
      <MainPageContainer>
        <BackgroundImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/about_background.svg"/>
  
        <StyledWrapper>
         
          <ContentContainer>
          <SectionTitle>About us</SectionTitle>
            <MainHeading>
              Music For Everyone 
            </MainHeading>
            <Description>
              At jammin.ai, we're a passionate bunch of musicians on a mission: to empower everyone to <br></br>unlock their inner musician. We know the struggle of deciphering complex chords or feeling<br></br> limited by sheet music. That's why we created jammin.ai - an innovative platform that<br></br> instantly generates chords and lyrics for any song, in any language.
            </Description>
          </ContentContainer>
        </StyledWrapper>
      </MainPageContainer>
    );
  };

  const ContentWrapper_mob = () => {

    return (
      <MainPageContainer1>
        <BackgroundImage1 loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/about_background.svg"/> 
  
        <StyledWrapper1>
         
          <ContentContainer>
             <SectionTitle>About us</SectionTitle>
            <Description1>
              At jammin.ai, we're a passionate bunch of musicians on a mission: to empower everyone to unlock their inner musician. We know the struggle of deciphering complex chords or feeling limited by sheet music. That's why we created jammin.ai - an innovative platform that instantly generates chords and lyrics for any song, in any language.
            </Description1>
          </ContentContainer>
        </StyledWrapper1>
      </MainPageContainer1>
    );
  };
  
  const MainPageContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Center content vertically */
    align-items: center;  /* Center content horizontally */
    overflow: hidden;
    position: relative;
    width: 100%;    
    height: 556px; 
    overflow-x: hidden; 
    overflow-y: hidden; 
    @media (max-width: 991px) {
      padding: 0px;
      width: 100%; 
      height: 428px;
    }
  `;

  const MainPageContainer1 = styled.section`
   
    @media (max-width: 991px) {
      display: flex;
    flex-direction: column;
    justify-content: center;  /* Center content vertically */
    align-items: center;  /* Center content horizontally */
    overflow: hidden;
    position: relative;
    width: 100%;    
    height: 400px; 
    overflow-x: hidden; 
    overflow-y: hidden; 
    }
  `;
  
  
  const BackgroundImage = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  `;

  const BackgroundImage1 = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
     object-fit: cover;
    object-position: center;
  `;
  
  const Description = styled.p`
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.24px;
    margin-top: 0px;
    color: white;
    text-align: center;  /* Center text */
  
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;

  const Description1 = styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-top: 0px;
    color: white;
    text-align: center;  /* Center text */
  
    @media (max-width: 991px) {
      max-width: 100%;
      font-size: 14px;
    }
  `;
  
  const MainHeading = styled.h1`
    font-size: 52px;
    font-weight: 900;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    color: white;
    text-align: center;  /* Center text */
  
    @media (max-width: 991px) {
      max-width: 100%;
      font-size: 40px;
    }
  `;
  
  const SectionTitle = styled.h2`
    font-size: 24px;
    font-weight: 900;
    margin-bottom:0px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    text-align: center;  /* Center text */
    color:white;
  
    @media (max-width: 991px) {
      max-width: 100%;
        font-size: 16px;
    }
  `;
  
  const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    max-height:10px;
    flex-direction: column;
    justify-content: center;  /* Center content vertically */
    align-items: center;  /* Center content horizontally */
    text-align: center;  /* Center align text */
   
  
    @media (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 10px;
    }
  `;

  const StyledWrapper1 = styled.div`
    position: relative;
    display: flex;
    max-height:10px;
    padding-left:10px;
    padding-right:10px;
    flex-direction: column;
    justify-content: center;  /* Center content vertically */
    align-items: center;  /* Center content horizontally */
    text-align: center;  /* Center align text */
   
  
    @media (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 10px;
    }
  `;
  
  const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Center content vertically */
    align-items: center;  /* Center content horizontally */
    margin-top: 0px;
    margin-bottom:0px;
    width: 100%;
    height:30px;
    gap:0px;
  
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  

export default About_us;