import React, { useState, useEffect, useRef } from "react";
import { styled, keyframes } from "styled-components";
import axios from "axios";
import config from "../../config";
import { getToken, getfirstname, getlastname } from "../utils";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { clearToken } from "../utils";
import {
    sign_in_btn
  } from "../../assets/images";
  import Login from '../login/login'


const SharePage1 = ({ onClose ,id }) => {
  const navigate = useNavigate();
  const token = getToken();
  const firstName = getfirstname();
  const lastName = getlastname();
  const [shareId, setShareId] = useState("");
  const [title, settitle] = useState("");
  const [img, setimg] = useState("");
  const [loading, setLoading] = useState(false);
  const [share_id, setshare_id] = useState("");
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);

  useEffect(() => {
    

    if (id) {
      setShareId(id);
      get_share_details(id);
      localStorage.removeItem("share_id_added");
      localStorage.setItem("share_id_added_after_login", id);
    }
  }, []);

  const get_share_details = async (id) => {
    const encodedRequestId1 = encodeURIComponent(id);
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/share/info/${encodedRequestId1}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json(); // Parse the response to JSON
        const title1 = data.url_request.title;
        const img1 = data.url_request.thumbnail_url;
        const share_id1 = data.share.share_id;
      
        settitle(title1);
        setimg(img1);
        setshare_id(share_id1);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      setLoading(false);
    } finally {
       setLoading(false);
    }
  };

  const open_login = () => {
    const popup = "login_popup";
    localStorage.setItem("login_popup",popup);
    navigate("/");
    onClose(); 
  };
  

  const handleClosePopup = () => {
    localStorage.removeItem("share_id_added");
    onClose();
  };


  

  //   const  imageUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/d46c3eed87be96c7783f487e5e99b1e40f565e8e5ee4cc75fe03927f7e1c94d7?placeholderIfAbsent=true&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc";
  //   const overlayUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/2be70ac1d7d61fa2af16f05036f0a0bfa3bb01cd4177ca429739fe641c21b315?placeholderIfAbsent=true&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc";
  //   const title="Perfect Ed Sheeran";
  return (
    <PopupContainer>
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <HeaderContainer>
        {/* <Greeting>Hey {firstName || lastName ? `${firstName} ${lastName}` : ''}</Greeting> */}
        <Greeting>Hey Jammer!</Greeting>
        <CloseButton onClick={handleClosePopup}>×</CloseButton>
       
      </HeaderContainer>

      <SongCardContainer>
        <AlbumCover2 loading="lazy" src={img} />
        <SongInfo2>
          <SongTitle2>{title}</SongTitle2>
        </SongInfo2>
      </SongCardContainer>

      <Message>You’ve been invited to check out these cool chords! Sign in or sign up to add them to your music and start jamming.</Message>

      <Button src={sign_in_btn}  onClick={open_login}/>
      {/* {isLoginPopupVisible && (
        <PopupOverlay>
          <Login onClose={handleClosePopup} />
        </PopupOverlay>
      )} */}
    </PopupContainer>
  );
};

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
  @media (max-width: 991px) {
    right: 15px;
  }
`;

const SongCardContainer = styled.article`
  margin-top: 0px;
 
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 40%; // Fixed width
  height: 55%; // Fixed height
  position: relative;
  background-color: #fff; // Ensure it's visible
  justify-content: center; // Vertically center items
  align-items: center; // Horizontally center items
  box-sizing: border-box; // Include padding and border in dimensions
`;

const AlbumCover2 = styled.img`
  width: 100%;

  border-radius: 8px 8px 0 0; // Round top corners
`;

const SongInfo2 = styled.div`
  border: 1px solid rgba(222, 227, 235, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center items vertically
  align-items: center; // Center items horizontally
  padding: 5px; // Adjust padding for aesthetics
  border-radius: 0 0 8px 8px; // Round bottom corners
  width: 100%; // Full width of the container
  box-sizing: border-box; // Include padding and borders in dimensions
  height: 50px; // Fixed height
`;

const SongTitle2 = styled.h3`
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0; // Remove top margin
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 1); // Adjust to show only one line

  @media (max-width: 991px) {
    font-size: 12px; // Adjust font size for smaller screens
  }
`;

const Button = styled.img`
  align-self: center;
   cursor: pointer;
 
`;

const PreviewContainer = styled.article`
  border-radius: 8px;
  align-self: center;
  display: flex;
  margin-top: 28px;
  width: 212px;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 1.262;
  width: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const OverlayImage = styled.img`
  aspect-ratio: 1.26;
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const Title = styled.h2`
  background-color: #fff;
  width: 100%;
  color: #404a4c;
  text-align: center;
  letter-spacing: -0.14px;
  padding: 16px 12px;
  font: 600 14px/20px Poppins, sans-serif;
  border: 1px solid #dee3eb;
`;

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: #fff;
  text-align: center;
  line-height: 1.2;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PopupContainer = styled.section`
  border-radius: 12px;
  background-color: #282828;
  position: relative;
  display: flex;
  max-width: 430px;
  flex-direction: column;
  overflow: hidden;
  padding: 36px 16px;
  width: 100%;

  /* Center content */
  justify-content: center;
  align-items: center; /* Aligns SongCardContainer to the center horizontally */
 
  height: 370px; /* Optional: Adjusts height to center vertically within the view */
  @media (max-width: 991px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Greeting = styled.h1`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.24px;
  align-self: center;
  margin-top: 5px;
`;

const Message = styled.p`
  margin-top: 15px;
  margin-left:15px;
  margin-right:15px;
  color:white;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default SharePage1;
