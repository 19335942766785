import React from "react";
import styled from "styled-components";
 import { Sanjay_2 ,shrikant} from '../../assets/images';
import image_url from "../../config"


const leaderData = [
  {
    name: "Sanjay Dorairaj",
    image:Sanjay_2,
    link: "https://www.linkedin.com/in/dsanjay/",
    bio: "Sanjay brings a diverse background in artificial intelligence, multimedia, cable, telecom, finance, charity, and social sectors, excelling in building high-performing teams and transforming innovative ideas into reality. As a passionate guitarist, his deep understanding of music, combined with advanced AI knowledge, enables him to create user-friendly tools tailored to the needs of musicians worldwide."
  },
  {
    name: "Shrikant Latkar",
    
    image:shrikant,
    link: "https://www.linkedin.com/in/shrikantlatkar/",
    bio: "Shrikant Latkar complements Sanjay's technical expertise with his business acumen and leadership skills. He has successfully launched and scaled multiple solutions across various domains, from micro-learning for gig workers to mobile advertising for global brands. Shrikant has built and led high-performing teams and established strong customer relationships and partnerships. Currently, he is focused on creating a Gen AI startup that leverages artificial intelligence to understand and influence user behavior."
  }
];

function Third_section() {
  return (
    <Section>
      <Heading>
        <span className="highlight">Led by</span>{" "}
        <span className="highlight">Tech</span> Visionarys
      </Heading>
      <LeaderCardContainer>
        {leaderData.map((leader, index) => (

           <Card>
           <LeaderImage src={leader.image} alt={`Portrait of ${leader.name}`} loading="lazy" />
           <LeaderName>{leader.name}</LeaderName>
         </Card>

        ))}
      </LeaderCardContainer>
      <BioContainer>
        <IntroText>
          Led by tech visionary{" "}
          <a href={leaderData[0].link} className="leader-link" target="_blank" rel="noopener noreferrer">
            {leaderData[0].name}
          </a>{" "}
          and successful entrepreneur{" "}
          <a href={leaderData[1].link} className="leader-link" target="_blank" rel="noopener noreferrer">
            {leaderData[1].name}
          </a>
          , Jammin.ai is revolutionizing the music industry through the power of artificial intelligence, empowering musicians of all levels with cutting-edge tools and resources
        </IntroText>
        {leaderData.map((leader, index) => (
          <Biography>{leader.bio}</Biography>
        ))}
        <ConclusionText>
          Under the guidance of Sanjay and Shrikant, Jammin.ai is continually evolving, solidifying its role as a valuable resource for musicians. With a shared vision of revolutionizing the music industry through AI, they are poised to take Jammin.ai to new heights, empowering musicians to jam like never before.
        </ConclusionText>
      </BioContainer>
    </Section>
  );
}

const Card = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 431px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const LeaderImage = styled.img`
  aspect-ratio: 1.06;
  object-fit: contain;
  object-position: center;
  width: 431px;
  border-radius: 12px;
  max-width: 100%;
`;

const LeaderName = styled.p`
  margin-top: 10px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  letter-spacing: -0.18px;
  line-height: 1.4;
`;
const Biography = styled.p`
  margin-top: 17px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  background-color:#121212;
  padding-left:35px;
  padding-right:35px;
  @media (max-width: 991px) {
   padding-left:10px;
  padding-right:10px;
  }
`;

const Heading = styled.h1`
  color: #fff;
  letter-spacing: -0.44px;
  text-transform: uppercase;
  align-self: center;
  font: 900 44px/1.2 Poppins, sans-serif;
  
  .highlight {
    color: #fff;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    font: 800 20px/1.2 Poppins, sans-serif;
  }
`;

const LeaderCardContainer = styled.div`
  display: flex;
  margin-top: 41px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px 80px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const BioContainer = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 28px;

  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 14px;
     line-height: 20px;
  }
`;

const IntroText = styled.p`
  width: 100%;

  .leader-link {
    text-decoration: underline;
    color: inherit;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ConclusionText = styled.p`
  margin-top: 17px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default Third_section;