import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { search, youtube,arrow ,user_help} from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import Playlist from "./playlist";


function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

function YouTubesearch({ onClose, id }) {

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  const navigate = useNavigate();
  const youtubeLinkRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const Getchord = () => {

    if(isChecked){
      localStorage.setItem('toggle_checked', isChecked);
    }

    const youtubeLink = youtubeLinkRef.current.value;

    localStorage.setItem('youtubeLink', youtubeLink);
    onClose();
   

    if (id === "playlist") {
      navigate('/all_songs');
    } else {
      navigate('/user');
    }
  };

  const handleToggle = () => {
    setIsChecked(prevState => !prevState);
  };

  return (
    <Container>
      <CloseButton onClick={onClose}>×</CloseButton>
      <ContentWrapper>
        <InputIcon1 loading="lazy" src={youtube} />
        <MainTitle> Paste the YouTube URL link
          {isMobile ? <><br /> to find the chords for<br /> your song</> : <> to find the<br /> chords for your song</>}</MainTitle>
        <FormContainer>
          <InputGroup>
            <InputIcon loading="lazy" src={search} />
            <InputField type="text" id="youtubeLink" ref={youtubeLinkRef} placeholder="Paste your Youtube link here" onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                Getchord();
              }
            }} />



           



          </InputGroup>
          {/* <SkipSection>
              <SkipLabel>Skip Vocal Separation</SkipLabel>
              <ToggleSwitch onClick={handleToggle}>
                <SwitchTrack isChecked={isChecked}>
                  <SwitchKnob isChecked={isChecked} />
                </SwitchTrack>
              </ToggleSwitch>

              <InfoButtonWrapper>
                <InfoIcon loading="lazy" src={user_help} />
                <Tooltip>
                  Vocal Separation can result in faster<br />
                  processing times althrough the quality<br />
                  may be affected

                </Tooltip>
                <Imagearrow src={arrow} alt="Arrow" />
              </InfoButtonWrapper>
            </SkipSection> */}
          <Button type="button" onClick={Getchord}>
            Go
          </Button>
        </FormContainer>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #121212;
  height: 464px;
  width: 700px;
  padding: 20px;

  @media (max-width: 991px) {
    padding: 10px;
    max-width: 85%;
    height:55%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const Button = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0072D5;
  color: #fff;
  font-weight: 600;
  font-size:16px;
  padding: 14px 12px;
  height:64px;
  width: 160px;
  cursor: pointer;
  border:none;

  @media (max-width: 991px) {
    padding-left: 20px;
    height:54px;
  }
`;


const ContentWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: center;
  letter-spacing: -0.28px;
  align-self: center;
  font: 600 28px/120% Poppins, sans-serif;

  @media (max-width: 991px) {
    font: 600 18px/120% Poppins, sans-serif;
   
  }
`;

const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
  gap: 24px;
  width:80%;
  

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 20px;
    width:100%;
    flex-direction: column;
    margin-left:0px;
   
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
  color: #51606f;
  font-weight: 300;
  padding: 14px 12px;
  width:100%;

  @media (max-width: 991px) {
    display: flex;
    width:90%;
    flex-direction: row;
   
  }
`;

const InputIcon = styled.img`
  aspect-ratio: 1;
  width: 32px;
 
`;

const InputIcon1 = styled.img`
margin-top:20px;
  align-items: center;
  width: 120px;
  @media (max-width: 991px) {
    width: 90px;
   
  }
 
`;



const InputField = styled.input`
  border: none;
 
  outline: none;
  font: inherit;
  color: inherit;
  background: transparent;
  width: 100%;
`;

const SkipSection = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: end;
  margin: auto 0;
`;

const SkipLabel = styled.label`
  color:white;
  align-self: center;
  font: 600 13px Nunito, sans-serif;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis if text overflows */
`;

const ToggleSwitch = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36px;
  margin: auto 0;
  padding: 3px 0;
`;

const SwitchTrack = styled.div`
  border-radius: 360px;
  background: ${({ isChecked }) => (isChecked ? '#0072D5' : '#b9c8da')}; // Change color based on state
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
`;

const SwitchKnob = styled.div`
  filter: drop-shadow(-1px 1px 1px rgba(51, 51, 51, 0.25));
  background-color: #fff;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  width: 20px;
  height: 20px;
  transform: ${({ isChecked }) => (isChecked ? 'translateX(20px)' : 'translateX(0)')}; // Move knob based on state
  transition: transform 0.3s ease;
`;

const InfoIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 30px;
  align-self: stretch;
  margin: auto 0;
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 40px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 22px; 
  left: 26%;
  transform: rotate(180deg)
`;

const Tooltip = styled.div`
  position: absolute;
  top: 150%; 
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; 
  z-index: 1;
  font-size: 12px;
`;

const InfoButtonWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`




export default YouTubesearch;