import React from "react";
import styled from "styled-components";


const featureData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0dadacb8f0cf1ec611b3360e2702ee227789bb0819ae77c5b66efd3f1fea72dd?apiKey=2d7c5855cb2f42a1b4e6163799d67ecc&&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc",
    title: "Made by Musicians, for Musicians:",
    description: "We understand your struggles and aspirations."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0dadacb8f0cf1ec611b3360e2702ee227789bb0819ae77c5b66efd3f1fea72dd?apiKey=2d7c5855cb2f42a1b4e6163799d67ecc&&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc",
    title: "Effortless jamming:",
    description: "Instantly access chords and lyrics for any song, anywhere."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0dadacb8f0cf1ec611b3360e2702ee227789bb0819ae77c5b66efd3f1fea72dd?apiKey=2d7c5855cb2f42a1b4e6163799d67ecc&&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc",
    title: "Accessible & Inclusive:",
    description: "Perfect for beginners and seasoned players alike."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0dadacb8f0cf1ec611b3360e2702ee227789bb0819ae77c5b66efd3f1fea72dd?apiKey=2d7c5855cb2f42a1b4e6163799d67ecc&&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc",
    title: "Always Evolving:",
    description: "We're constantly adding new features and functionalities."
  }
];

function Fifth_Section() {
  return (
    <Section>
      <Container>
        <Content>
          <Heading>
            <span className="highlight">HERE'S WHAT MAKES</span>{" "}
            <span className="highlight">jammin.ai</span>{" "}
            <span>SPECIAL</span>
          </Heading>
          <FeatureGrid>
            {featureData.map((feature) => (
               <Card>
               <CardContent>
                 <Icon src={feature.icon} alt=""/>
                 <TextContent>
                   <Title>{feature.title}</Title>
                   <Description>{feature.description}</Description>
                 </TextContent>
               </CardContent>
             </Card>
              
            ))}
          </FeatureGrid>
        </Content>
      </Container>
    </Section>
  );
}

const Card = styled.div`
  background-color: rgba(45, 45, 45, 1);
  display: flex;
  min-width: 240px;
  min-height: 304px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 328px;
  @media (max-width: 991px) {
   min-height: 200px;
   height:200px;
  }

`;
const CardContent = styled.div`
  display: flex;
  max-width: 100%;
  width: 311px;
  flex-direction: column;
  justify-content: start;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 56px;
  align-self: center;
`;
const TextContent = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 991px) {
   margin-top: 3px;
  }
`;

const Title = styled.h3`
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
  line-height: 1.4;
   @media (max-width: 991px) {
   font-size:18px;
  }
`;
const Description = styled.p`
  color: rgba(213, 213, 213, 1);
  font-weight: 600;
  line-height: 25px;
  margin-top: 4px;
   @media (max-width: 991px) {
   font-size:14px;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  background-color:#121212;
  justify-content: center;
  padding: 40px;
  @media (max-width: 991px) {
    padding: 0 20px;
   }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px 26px;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Content = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Heading = styled.h2`
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.4px;
  
  font: 900 40px/1.2 Poppins, sans-serif;
  .highlight {
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
  }
  @media (max-width: 991px) {
    max-width: 100%;
    font: 900 18px/1.2 Poppins, sans-serif;
  }

  
`;

const FeatureGrid = styled.div`
  align-self: center;
  display: flex;
  margin-top: 20px;
  gap: 17px;
  letter-spacing: -0.18px;
  justify-content: start;
  flex-wrap: wrap;
  font: 18px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom:20px;
     justify-content:center;
    item-align:center;
  }
`;

export default Fifth_Section;