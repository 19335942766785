import React, { useEffect,useState } from 'react';
import styled,{ keyframes } from 'styled-components';
import { Billie } from '../../assets/images';
import Footer from '../footer_lan';
import Pre_nav from '../navigation/premium_nav';
import axios from 'axios';
import { getToken,clearToken } from '../utils';
import config from '../../config';

import { useNavigate } from 'react-router-dom';







const Public_song = () => {
    const [songs, setsong] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const token =getToken();
    if(token==null){
      navigate("/");
    }
  

    useEffect(() => {
        const fetchPlaylistSongs = async () => {
            setLoading(true);
          try {
           
    
            const response = await axios.get(`${config.apiUrl}/public/`, {
              headers: {
                'Content-Type': 'application/json'
               
              }
            });
    
          
    
            if (response.status === 200) {
              const mappedRecords = response.data.map(item => {
                  const thumbnailUrl = item.thumbnail_url.split('?')[0];
                  return {
                      requestId: item.request_id,
                      url: item.url,
                      title: item.title,
                      thumbnail_url: thumbnailUrl,
                      youtube_id: item.youtube_id
                  };
              });
              
              setsong(mappedRecords);
          }
          
    
          } catch (error) {
            if(error.response && error.response.status === 401) {
              clearToken();
              navigate("/");
            } 
            
          } finally {
            setLoading(false);
          }
        };
    
        fetchPlaylistSongs();
      }, []);





    //   const handleSongCardClick = async (Id) => {
    //     try {
           
    //       const response_status = await axios.get(`${config.apiUrl}/public/${Id}`, {
    //         headers: {
    //             'Content-Type': 'application/json'

    //         }
    //       });
    
         
    //       if (response_status.status === 200) {
    //         if (response_status.data.state === 'complete') {
    //             navigate('/chords', { state: { result1: response_status.data} });
    //           setLoading(false); 
    
    //         } else if (response_status.data.state === 'pending' || response_status.data.state === 'in_progress') {
    //           setLoading(true);
    //           setTimeout(() => handleSongCardClick(Id), 1000); 
    //         } else {
    //           setLoading(false);
    //          // setError("Try again!"); 
    //         }
    //       }
    
    //     } catch (error) {
    //       console.error('Status check failed:', error);
    //       // Handle error
    //     }
    //   };

    const handleSongCardClick = (Id) => {
        navigate(`/chords/public/youtube/${Id}`);
    };
    
    
      return (
        <>
          <Pre_nav />
          
          <SongListContainer>
            <MainTitle>Beginner-Friendly Songs to Start Jamming!</MainTitle>
            <Overlay loading={loading}>
                <Spinner />
                {/* <LoadingText>Loading... Please wait</LoadingText> */}
            </Overlay>
            <SongGrid>
              {songs.map((song, index) => (
                <SongCardContainer key={index} onClick={() => handleSongCardClick(song.youtube_id)}>
                  <AlbumCover loading="lazy" src={song.thumbnail_url} alt={`Album cover for ${song.title}`} />
                  <SongInfo>
                    <SongTitle>{song.title}</SongTitle>
                  </SongInfo>
                </SongCardContainer>
              ))}
            </SongGrid>
          </SongListContainer>
          <Footer />
        </>
      );
    };

export default Public_song;

const SongListContainer = styled.main`
  display: flex;
  flex-direction: column;
   min-height: 80vh;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  padding: 20px 32px;
  background-color: #020F12;
  height:100%;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: left;
  letter-spacing: -0.24px;
  font: 600 24px/160% 'Poppins', -apple-system, Roboto, Helvetica, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SongGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 991px) {
    gap: 10px;
  }
`;

const SongCardContainer = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 258px;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
  }
`;

const AlbumCover = styled.img`
  aspect-ratio: 1.27;
  
  width: 100%;
  border-radius: 8px 8px 0 0;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

const SongInfo = styled.div`
  border: 1px solid rgba(222, 227, 235, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px 0 18px;
  border-radius: 0 0 8px 8px;
`;



const SongTitle = styled.h3`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 2); // Line height * number of lines
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;


const SongArtist = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0;
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

export const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
z-index: 999; /* Ensure it's above other content */
display: ${props => (props.loading ? 'flex' : 'none')};
flex-direction: column;
justify-content: center;
align-items: center;
color: white;
font-size: 1.5em;
`;

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;


export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingText = styled.div`
  text-align: center;
  color:#fff;
`;


