import React, { useState } from "react";
import styled from "styled-components";
import { loginImage, close, logo_black,blur } from '../../assets/images';
import GoogleSignIn from '../../google'; 

function Chords_Login({onClose}) {
  

  return (
    <MainContainer>
         <BackgroundImage/>
         <ContentContainer>
                <LeftColumn>
                    <StyledImg loading="lazy" src={loginImage} />
                </LeftColumn>
                <RightColumn>
                    <FormContent>
                        <CloseButton onClick={onClose}>
                            <img loading="lazy" src={close} alt="Close" />
                        </CloseButton>

                        <LogoContainer>
                            <LogoImg loading="lazy" src={logo_black} />
                        </LogoContainer>

                        <FormContainer>
                            <FormText>Sign up or Login to access chords and lyrics.</FormText>
                            <div>
                                <GoogleSignIn />
                            </div>
                        </FormContainer>
                    </FormContent>
                </RightColumn>
            </ContentContainer>
     
    </MainContainer>
  );
}

const MainContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media (max-width: 991px) {
    width:95%;
    margin-left:10px;
    justify-content: center;

  }
  
`;


const BackgroundImage = styled.div`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: url(${blur});
  background-size: cover;
  background-position: center;
  z-index: -1; /* Add this */
`;

const ContentContainer = styled.section`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const LeftColumn = styled.aside`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RightColumn = styled.section`
  flex: 1;
  display: flex;
  background-color:white;
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;

  img {
    width: 24px;
    height: 24px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top:80px;
`;

const LogoImg = styled.img`
  width: 200px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormText = styled.p`
  color: #0e0402;
  text-align: center;
  margin-top: 25px;
  font: 600 16px/130% Poppins, sans-serif;
  margin-bottom:90px;
`;


export default Chords_Login;
