

export const getToken = () => localStorage.getItem('access_token');
export const getimgurl = () => localStorage.getItem('user_img_url');
export const getfirstname = () => localStorage.getItem('user_first_name');
export const getlastname = () => localStorage.getItem('user_last_name');
export const getemail = () => localStorage.getItem('user_email');
export const getsubscription = () => localStorage.getItem('subscription');
export const getsubscription_date = () => localStorage.getItem('subscription_date');
export const getsubscription_enddate = () => localStorage.getItem('subscription_enddate');
export const getsubscription_status = () => localStorage.getItem('subscription_status');
export const getsubscription_type = () => localStorage.getItem('subscription_type');
export const terms_accepted = () => localStorage.getItem('terms_accepted');


export const image_url = () => "https://jammin-stage.s3.amazonaws.com/images";


export const clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_img_url');
  };


  export const setLoadingStatus = (requestId, status) => {
    const currentStatus = JSON.parse(localStorage.getItem('loadingStatus')) || {};
    currentStatus[requestId] = status;
    localStorage.setItem('loadingStatus', JSON.stringify(currentStatus));
};

export const getLoadingStatus = () => {
    return JSON.parse(localStorage.getItem('loadingStatus')) || {};
};

export const removeLoadingStatus = (requestId) => {
    const currentStatus = JSON.parse(localStorage.getItem('loadingStatus')) || {};
    delete currentStatus[requestId];
    localStorage.setItem('loadingStatus', JSON.stringify(currentStatus));
};





 
 



