import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  jamminai,
  get_started,
  search,
  Jammin_mob,
  login_btn,
  navi,
  new_logo,
  lg_white,
} from "../../assets/images";
import Landing from "./landing";
import Pricing from "./landing_pricing";
import Contact from "./lan_contact_us";
import Login from "../login/login";
import Signup from "../signup/signup";
import Chords_Login from "../login/chords_login";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils";
import GoogleSignIn from "../../google";
import Videofile from "../../assets/video/guitar1.gif";
import SharePage1 from "../chords/share_receive_signup_popup";

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

function Main() {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  return (
    <div className="App">
      {isMobile ? <NavbarMob /> : <Navbar />}
      <Section id="home-section">
        <Group />
      </Section>
      <Landing />
      <Section id="pricing-section">
        <Pricing />
      </Section>
      <Section id="contact-section">
        <Contact />
      </Section>
    </div>
  );
}

function Navbar() {
  const token = getToken();
  const navigate = useNavigate();

  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const [isSignupPopupVisible, setSignupPopupVisible] = useState(false);
  const [activeLink, setActiveLink] = useState("home-section");
  const login_popup = localStorage.getItem("login_popup");

  const handleLoginClick = () => {
    setLoginPopupVisible(true);
  };

  const handleClosePopup = () => {
    setLoginPopupVisible(false);
  };

  const handleSingupClick = () => {
    setSignupPopupVisible(true);
  };

  const handleSignupClosePopup = () => {
    setSignupPopupVisible(false);
  };

  const handleChordsClick = () => {
    navigate("/user");
    window.scrollTo(0, 0);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setActiveLink(id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      // console.warn(`Element with id '${id}' not found.`);
    }
  };

  const contact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (login_popup) {
      setLoginPopupVisible(true);
      localStorage.removeItem("login_popup");
    }
  }, [login_popup]);

  return (
    <>
      <Header>
        <NavBar>
          <Logo src={new_logo} alt="Brand Logo" loading="lazy" />
          <Nav>
            <NavLink
              isActive={activeLink === "home-section"}
              onClick={() => scrollToSection("home-section")}
            >
              Home
            </NavLink>
            <NavLink
              isActive={activeLink === "pricing-section"}
              onClick={() => scrollToSection("pricing-section")}
            >
              Pricing
            </NavLink>
            <NavLink
              isActive={activeLink === "contact-section"}
              onClick={contact}
            >
              Contact Us
            </NavLink>
          </Nav>
          <Actions>
            {token ? (
              // <ActionButton onClick={handleChordsClick}>My Music</ActionButton>
              <Button5 type="button" onClick={handleChordsClick}>
                My Music
              </Button5>
            ) : (
              <>
                <ActionButton onClick={handleLoginClick}>Sign in</ActionButton>
                {/* <ActionButton onClick={handleSingupClick}>Create an account</ActionButton> */}
                {/* <ActionButton >Create an account</ActionButton> */}
                {/* <div>
                  <GoogleSignIn />
                </div> */}
              </>
            )}
          </Actions>
        </NavBar>
      </Header>
      {isLoginPopupVisible && (
        <PopupOverlay>
          <Login onClose={handleClosePopup} />
        </PopupOverlay>
      )}
      {isSignupPopupVisible && (
        <PopupOverlay>
          <Login onClose={handleSignupClosePopup} />
        </PopupOverlay>
      )}
    </>
  );
}

function NavbarMob() {
  const navigate = useNavigate();
  const login_popup = localStorage.getItem("login_popup");
  useEffect(() => {
    if (login_popup) {
      setLoginPopupVisible(true);
      localStorage.removeItem("login_popup");
    }
  }, [login_popup]);
  const navi1 = () => {
    navigate("/login");
  };

  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const [isSignupPopupVisible, setSignupPopupVisible] = useState(false);
  const [activeLink, setActiveLink] = useState("home-section");

  const handleLoginClick = () => {
    setLoginPopupVisible(true);
  };

  const handleClosePopup = () => {
    setLoginPopupVisible(false);
  };
  const handleSingupClick = () => {
    setSignupPopupVisible(true);
  };

  const handleSignupClosePopup = () => {
    setSignupPopupVisible(false);
  };

  const handleChordsClick = () => {
    navigate("/user");
  };
  const token = getToken();
  return (
    <>
      <Header3>
        <NavBar>
          <Logo3 src={new_logo} alt="Brand Logo" loading="lazy" />
          <Actions1>
            {token ? (
              // <ActionButton onClick={handleChordsClick}>My Music</ActionButton>
              <Button5 type="button" onClick={handleChordsClick}>
                My Music
              </Button5>
            ) : (
              <>
                <ActionButton onClick={handleLoginClick}>Sign in</ActionButton>
                {/* <ActionButton onClick={handleSingupClick}>Create an account</ActionButton> */}
                {/* <ActionButton >Create an account</ActionButton> */}
                {/* <div>
                  <GoogleSignIn />
                </div> */}
              </>
            )}
          </Actions1>
        </NavBar>
      </Header3>
      {isLoginPopupVisible && (
        <PopupOverlay>
          <Login onClose={handleClosePopup} />
        </PopupOverlay>
      )}
      {isSignupPopupVisible && (
        <PopupOverlay>
          <Login onClose={handleSignupClosePopup} />
        </PopupOverlay>
      )}
    </>
  );
}

const Group = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  const navigate = useNavigate();
  const youtubeLinkRef = useRef(null);
  const [isSignupPopupVisible, setSignupPopupVisible] = useState(false);
  const token = getToken();
  const share_id_added = localStorage.getItem("share_id_added");
  const [showsharePopup, setshowsharePopup] = useState(false);

  useEffect(() => {
    if (share_id_added) {
      setshowsharePopup(true);
    }
  }, [share_id_added]);

  const handleSignupClosePopup = () => {
    setSignupPopupVisible(false);
  };

  const handleShowSharePopup = (value) => {
    setshowsharePopup(value);
  };

  const Getchord = () => {
    const youtubeLink = youtubeLinkRef.current.value;
    localStorage.setItem("youtubeLink", youtubeLink);
    if (!token) {
      setSignupPopupVisible(true);
    } else {
      navigate("/user");
    }
    // navigate('/signup1', { state: { youtubeLink } });
  };

  return (
    <MainPageContainer>
      <BackgroundImage loading="lazy" src={Videofile} />
      <div>
        {isMobile ? (
          <Title>
            Discover Chords and Lyrics for Any Song on YouTube in over 50
            languages
          </Title>
        ) : (
          <Title>
            Discover Chords and Lyrics for Any Song on YouTube in over 50
            languages
          </Title>
        )}
      </div>
      {/* <Subtitle>Learn to play your favorite songs by finding the perfect chords on Jammin.ai.</Subtitle> */}
      <FormContainer>
        <InputGroup>
          <InputIcon loading="lazy" src={search} />
          <InputField
            type="text"
            id="youtubeLink"
            ref={youtubeLinkRef}
            placeholder="Paste your Youtube link here"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (!token) {
                  setSignupPopupVisible(true);
                } else {
                  Getchord();
                }
              }
            }}
          />
        </InputGroup>
        <Button type="button" onClick={Getchord}>
          Go
        </Button>
      </FormContainer>
      {isSignupPopupVisible && (
        <PopupOverlay>
          <Chords_Login onClose={handleSignupClosePopup} />
        </PopupOverlay>
      )}
      {showsharePopup && (
        <PopupOverlay>
          <SharePage1
            onClose={() => handleShowSharePopup(false)}
            id={share_id_added}
          />
        </PopupOverlay>
      )}
    </MainPageContainer>
  );
};

const ActionButton = ({ children, onClick }) => (
  <StyledActionButton onClick={onClick} tabIndex="0">
    {children}
  </StyledActionButton>
);

const Header = styled.header`
  background-color: #312f2f;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  height: 70px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1000;
  position: fixed;
`;

const Header3 = styled.header`
  background-color: #312f2f;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  height: 60px;
  width: 100%;

  overflow-x: hidden;
  overflow-y: hidden;
`;
const Section = styled.section`
  padding: 0px;
`;

const NavLink = styled.a`
  font-family: "poppins", sans-serif;
  text-decoration: none;

  font-size: 18px;
  font-weight: ${(props) => (props.isActive ? "600" : "400")};
  color: inherit;
  cursor: pointer;
  &:focus {
    outline: 2px solid #fff;
  }
  &:hover {
  }
`;

const NavBar = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Logo = styled.img`
  aspect-ratio: 6.25;
  object-fit: auto;
  object-position: center;
  width: 187px;
  height: 50px;
  fill: #fff;
  align-self: stretch;
  max-width: 100%;
  margin: auto 0;
`;

const Logo3 = styled.img`
  width: 108px;
  flex-direction: row;
  fill: #fff;
  max-width: 100%;
  margin-left: 14px;
`;

const Logo4 = styled.img`
  object-fit: auto;
  width: auto;
  flex-direction: row;
  margin-left: 44px;
`;

const Logo5 = styled.img`
  flex-direction: row;
  fill: #fff;
  margin-left: 4px;
  margin-right: 0px;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  color: #fff;
  text-align: center;
  margin: auto 0;
  padding: 0 60px;
  margin-left: 100px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const StyledNavLink = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  gap: 32px;
`;

const Actions = styled.div`
  justify-content: flex-end;
  align-self: stretch;
  display: flex;
  gap: 12px;
  font-size: 16px;
  margin-right: 29px;
`;

const Actions1 = styled.div`
  justify-content: flex-end;
  align-self: stretch;
  display: wrap;
  gap: 7px;
  font-size: 16px;
  margin-right: 2px;
  overflow: hidden;
`;

const StyledActionButton = styled.button`
  font-family: "Poppins", sans-serif;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  border: none;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;
  background-color: #0072d5;
  align-items: center;
  color: #ffffff;
  width: 144px;
  height: 48px;
  white-space: nowrap;
  margin: auto 0;
  padding: 14px 12px;
  @media (max-width: 991px) {
    white-space: normal;
    padding: 0 20px;
    margin-right: 10px;
    height: 45px;
  }
`;

const MainPageContainer = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 991px) {
    padding: 0px;
    width: 100%;
    height: 428px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Title = styled.h1`
  position: relative;
  color: #fff;
  text-align: center;
  letter-spacing: -0.52px;
  margin: 176px 12px 0;
  font: 700 48px/62px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    text-align: center;
    line-height: 3px;
    margin: 1px 1px 0 0;
    font: 600 20px Poppins, sans-serif;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 70px;
  }
`;

const Subtitle = styled.p`
  position: relative;
  color: #fff;
  text-align: center;
  letter-spacing: -0.28px;
  margin: 32px 12px 0;
  font: 500 28px/120% Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 10px;
    font: 400 18px/60% Poppins, sans-serif;
    line-height: 28px;
  }
`;
const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center items horizontally */
  margin-top: 64px;
  gap: 12px;
  width: 100%;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
  color: #51606f;
  font-weight: 300;
  padding: 14px 12px;
  width: 500px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px 10px;
  }
`;

const InputIcon = styled.img`
  aspect-ratio: 1;
  width: 32px;
  @media (max-width: 991px) {
    aspect-ratio: 0;
  }
`;

const InputField = styled.input`
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: transparent;
  width: 100%;
`;

const Button = styled.button`
  border: none;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0072d5;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 14px 12px;
  height: 64px;
  width: 160px;
  cursor: pointer;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Button5 = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0072d5;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 14px 12px;
  height: 49px;
  width: 130px;
  cursor: pointer;
  border: none;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

export default Main;
