import React, { useRef, useState } from 'react';
import styled from "styled-components";
import { tick_icon, frame, frame1 } from '../../assets/images';
import { getToken } from "../utils";
import Explore from './explore'; 
import { useNavigate } from 'react-router-dom';


const Landing = () => {
  const token = getToken();
  const navigate = useNavigate();
  const [explorePopupVisible, setexplorePopupVisible] = useState(false);
  
  
  const explorenow = () => {
    if (token == null) {
     
      setexplorePopupVisible(true);
      
    } else {
      navigate("/user");
    }
  };
  const handleClosePopup = () => {
    setexplorePopupVisible(false);
  };

  return (
    <Container>
      <Container1>
        <Header>Our AI automatically generates the lyrics and the chords for any song</Header>
        {data.map((item, index) => (
          <Item key={index}>
            <ItemIcon src={item.tickIcon} alt={item.alt} />
            <ItemText>
              <HighlightedText>{item.highlightedText}</HighlightedText>
              {item.text}
            </ItemText>
          </Item>
        ))}
        <ButtonContainer onClick={explorenow}>
          <Img src={frame1} alt="Explore Now" />
          
        </ButtonContainer>
       
      </Container1>
      <NewImage src={frame} alt="Image description" />
      {explorePopupVisible && (
        <PopupOverlay>
          <Explore onClose={handleClosePopup} />
        </PopupOverlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  
  width: 100%;
  flex-direction: row;
  font-size: 16px;
  color: #020f12;
 
  gap:30px;
  font-weight: 400;
  line-height: 22px;
  margin-left:0px;
  overflow-x: hidden; 
  overflow-y: hidden; 
  background-color:#F0ECEB;
  @media (max-width: 991px) {
     flex-direction: column;
     
     
  }
  
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const Container1 = styled.div`

  order: 1;
  margin-left:30px;
   @media (max-width: 991px) {
     order: 2;
     flex-direction: row;
  }
  
`;

const NewImage = styled.img`
  width: 90%; /* Adjust width as needed */
  max-width: 90%;
  margin-left: 20px; /* Add margin for space */
  margin-right: 20px;
  overflow: hidden; 
  order: 2;
   @media (max-width: 991px) {
     order: 1;
      margin-left: 15px;
      margin-right: 0px;
  }
`;

const Header = styled.h1`

  letter-spacing: -0.32px;
  width: 100%;
  font: 600 32px/42px 'Montserrat', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font: 600 20px 'Poppins', sans-serif;
  }
`;



const Item = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: 12px;
  padding-right: 20px;
  gap: 4px;
  letter-spacing: -0.16px;
  flex-direction: row;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: row;
    
  }
`;

const Img = styled.img`
padding:0px;
cursor: pointer;

`;

const ItemIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 32px;
  margin-left:12px;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-left:0px;
    flex-direction: row;
    margin-bottom: 50px;
    
    
  }
`;

const ItemText = styled.p`
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 991px) {
    flex-direction: row;
     margin-left:0px;
  }
`;

const HighlightedText = styled.span`
  font-weight: 600;
  font: 600 'Montserrat', sans-serif;
  color: #020F12;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  max-width: 100%;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  padding: 0 36px;
  @media (max-width: 991px) {
    padding-left: 20px;
    align-items: center;
  }
`;

const ExploreButton = styled.button`
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  border-radius: 6px;
  background-color: #428BCA;
  padding: 14px 12px;
  text-color:#FFFFFF;
  cursor: pointer;
  border: none;
  outline: none;
   @media (max-width: 991px) {
    padding: 0 20px;
    height:50px;
  }
`;

const data = [
  {
    tickIcon: tick_icon,
    alt: "",
    highlightedText: "Play Any Song on Guitar: ",
    text: "Simply provide a link from YouTube Jammin.ai will transcribe it into clear guitar tabs."
  },
  {
    tickIcon: tick_icon,
    alt: "",
    highlightedText: "Focus on Playing: ",
    text: "We handle the music theory, so you can focus on what matters - mastering your favorite riffs and melodies."
  },
  {
    tickIcon: tick_icon,
    alt: "",
    highlightedText: "Download and Print: ",
    text: "Get your guitar sheet music instantly in a clean, easy-to-read PDF format. Print it out or keep it on your device for easy access."
  },
  {
    tickIcon: tick_icon,
    alt: "",
    highlightedText: "Free to Start: ",
    text: "Generate your first sheet music file for free! Subscription plans are available for unlimited music generation."
  }
];



export default Landing;