import React, { useState,useEffect } from "react";
import styled from "styled-components";
import {
  new_logo,
  premium,
  Jammin_mob,
  logo_new,
  free_user,
  premium_user_nav,
  lg_white,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { getToken, clearToken, getimgurl,getfirstname,getlastname } from "../utils";
import useSubscription from "../user-page/subscription";
import axios from 'axios';
import config from '../../config';

function Pre_nav() {
  const navigate = useNavigate();
  const token = getToken();
  const img_url = getimgurl();
  const firstName = getfirstname();
  const lastName = getlastname();
  const [showDropdown, setShowDropdown] = useState(false);
  const subscription1 = localStorage.getItem("subscription");



  const [imageSrc, setImageSrc] = useState();

  const fetchSubscription = async () => {
   

    try {
        const response = await axios.get(`${config.apiUrl}/subscription/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
          if (response.data && response.data.subscription_type) {
            setImageSrc(premium_user_nav);
        } else {
            setImageSrc(free_user); 
        }
          }
    } catch (error) {
        if(error.response && error.response.status === 401) {
            clearToken();
            navigate("/");
          } 
    }
};

  
  useEffect(() => {
    fetchSubscription();
   
  }, []);

 
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logout = () => {
  
    localStorage.removeItem("share_id_added");
    clearToken();
    navigate("/");
    setShowDropdown(false);
  };

  const myprofile = () => {
    navigate("/myprofile");
  };

  const mybilling = () => {
    navigate("/mybilling");
  };

  const mysetting = () => {
    navigate("/settings");
  };

  const user_navigate = () => {
   
    navigate("/user");
    window.scrollTo(0, 0);
  };

  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}`.toUpperCase() : 'U';

  return (
    <Header>
      <Nav1>
        <Logo
          loading="lazy"
          src={new_logo}
          alt="Logo"
          onClick={user_navigate}
        />
        <Logo1 loading="lazy" src={imageSrc} alt={imageSrc} />
        
      </Nav1>
      {/* <UserIcon >SI</UserIcon> */}
      {/* <img
        src={img_url}
        style={{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: "#f6b704",
          display: "flex",
          width: "48px",
          height: "48px",
          marginTop: "18px",
          marginRight: "15px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
        }}
        onClick={toggleDropdown}
      /> */}
       <div
        onClick={toggleDropdown}
        style={{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: img_url ? "transparent" : "#0072D5", // Blue background if no image
          display: "flex",
          width: "48px",
          height: "48px",
          marginTop: "18px",
          marginRight: "15px",
          color: img_url ? "transparent" : "#FFFFFF", // White text color if no image
          cursor: "pointer",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
          backgroundImage: img_url ? `url(${img_url})` : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {!img_url && initials}
      </div>
      {showDropdown && (
        <DropdownMenu>
          <MenuItem onClick={myprofile}>My Profile</MenuItem>
          <MenuItem onClick={mybilling}>Billing and Subscription</MenuItem>
          <MenuItem onClick={mysetting}>Account Settings</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </DropdownMenu>
      )}
    </Header>
  );
}

const Header = styled.header`
  background-color: #312f2f;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 0;
  @media (max-width: 991px){
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0;
    background-color: #312f2f;
    flex-direction: row;
  }
`;

const Nav1 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0px;
  @media (max-width: 991px) {
    flex-direction: row;
  }
`;

const Logo = styled.img`
  object-position: center;
  width: 138px;
  fill: #fff;
  max-width: 100%;
  margin-left: 20px;
  padding: 0;
  cursor: pointer;
  @media (max-width: 991px) {
     margin-left: 10px;
  }
`;

const Logo2 = styled.img`
  display: none;
  @media (max-width: 991px) {
    display: block;
    object-fit: auto;
    object-position: center;
    width: 108px;
    fill: #fff;
    max-width: 100%;
    margin-top: 20px;
  }
`;

const Logo1 = styled.img`
  flex-direction: row;
  margin-left: 6px;
  fill: #fff;
`;

const UserIcon = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f6b704;
  display: flex;
  width: 48px;
  height: 48px;
  margin-top: 18px;
  margin-right: 15px;
  color: #fff;
  cursor: pointer;
  font: 600 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 15px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export default Pre_nav;
