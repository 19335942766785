import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { del_but,white_close,change_pwd,error_icon } from '../../assets/images';

import axios from "axios";
import config from "../../config";
import Pre_nav from "../navigation/premium_nav";
import Footer from "../footer_lan";
import { getfirstname, getemail, getimgurl } from "../utils";
import { getToken, clearToken } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Settings() {
    const navigate = useNavigate();
    const token = getToken();
  if(token==null){
    navigate("/");
  }
    const [loading, setLoading] = useState(false);
    const [selectedNavItem, setSelectedNavItem] = useState("/settings");
    const [down, setDown] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reenteredNewPassword, setReenteredNewPassword] = useState('');

    const del_account = () => {
      setDown(true);
    };


    const showToast1 = (data) => {
      // console.log("Showing toast");
      toast(<CustomToast1 message={data} />, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
  
    const CustomToast1 = ({ message }) => {
      return (
        <div>
          <p>{message}</p>
        </div>
      );
    };

    const showToast2 = (data) => {
      // console.log("Showing toast");
      toast(<CustomToast2 message={data} />, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };


    const CustomToast2 = ({ message }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={error_icon}
          alt="check icon"
          style={{ marginRight: "8px", width: "24px", height: "24px" }}
        />
        <span style={{ flex: 1 }}>{message}</span>
      </div>
    );


    const handlePasswordChange = () => {
      if (!oldPassword || !newPassword || !reenteredNewPassword) {
        showToast2("Please fill all the fields");
          return;
      }

      if (newPassword !== reenteredNewPassword) {
        showToast2("New password and re-entered new password do not match");
          return;
      }

      // Make API call to change password using the captured values
      setLoading(true);

      axios
          .post(`${config.apiUrl}/users/change_password`, {
             "new_password": newPassword,
               "old_password": oldPassword
             
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => {
              if (response.status === 200) {
                showToast1("Password changed successfully");
              }
          })
          .catch(error => {
            showToast2("Error changing password");
          })
          .finally(() => {
              setLoading(false);
          });
  };


    return (
        <PageWrapper>
            <Pre_nav />
            <Overlay loading={loading}>
                <Spinner />
            </Overlay>
            <ContentWrapper>
                <Sidebar>
                    <NavItem onClick={() => navigate("/myprofile")}>
                      My Profile
                    </NavItem>
                    <NavItem onClick={() => navigate("/mybilling")}>
                      Billing and Subscription
                    </NavItem>
                    <NavItem selected={selectedNavItem === "/settings"}>
                      Settings
                    </NavItem>
                </Sidebar>
                <MainContent>
                    <StyledMainContent>
                    <UserInfoSection>
                        <Basic>Change Password</Basic>
                         <New1>
                        <SectionTitle>Enter Current password</SectionTitle>
                        <TextInput2
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                        </New1>
                        <New1>
                        <SectionTitle>Enter New passowrd</SectionTitle>
                        <TextInput2
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                        <SectionTitle>Re-enter new password</SectionTitle>
                        <TextInput2
                                    type="password"
                                    value={reenteredNewPassword}
                                    onChange={(e) => setReenteredNewPassword(e.target.value)}
                                />
                        </New1>
                        <New1>
                        <ResetButton4 src={change_pwd} onClick={handlePasswordChange} />
                        </New1>


                         {/* <SectionTitle>Gender</SectionTitle>
                        <TextInput type="text" placeholder="Gender" /> */}
                      </UserInfoSection>
                      <StyledAccountDeletionContainer>
                        <StyledDeletionTitle>Delete my Account</StyledDeletionTitle>
                        <StyledDeletionWarning>
                            This will immediately delete all your saved chords and lyrics
                        </StyledDeletionWarning>
                        <ActionButton src={del_but} onClick={del_account} />
                      </StyledAccountDeletionContainer>
                    </StyledMainContent>
                </MainContent>
            </ContentWrapper>
            <ToastContainer />

            <FooterWrapper>
                <Footer />
            </FooterWrapper>
            {down && (
            <PopupOverlay>
              <Downgrade onClose={() => setDown(false)}/>
              </PopupOverlay>
           
          )}
        </PageWrapper>
    );
}

function Downgrade({ onClose }) {
  const token = getToken();
  
  
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const delete_account = async () => {
    setLoading(true);

    try {
      const response = await axios.delete(
        `${config.apiUrl}/users/my`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        clearToken();
        navigate("/user");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } 
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button primary={primary} onClick={onClick}>
        {text}
      </Button>
    );
  };

  return (
    <Modal>
      <HeaderContainer1>
        <WarningText>Warning</WarningText>
        <CloseIcon onClick={onClose} loading="lazy" src={white_close} alt="Close" />
      </HeaderContainer1>
      <Content1>Are you sure you want to delete this account?</Content1>
      <ActionButton text="Confirm" primary onClick={delete_account} />
      <ActionButton text="Cancel" onClick={onClose} />
    </Modal>
  );
}

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height:300px;
  padding: 0 0 0px;
   flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 90%;
  }
`;

const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;
const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height:70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;
const Button = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${props => props.primary ? "#428bca" : "transparent"};
  align-self: center;
  margin-top: ${props => props.primary ? "15px" : "5px"};
  width: 100%;
  max-width: 296px;
  color: ${props => props.primary ? "#fff" : "#717171"};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const UserInfoSection = styled.div`
  color: white;
   width: 100%;
`;

const Basic = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-right: 0px;
    margin-top:10px;
`;
 

const New1 = styled.div`
  flex: 1; /* Ensures equal space for both First Name and Last Name */
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  font: 400 12px/22px Poppins, sans-serif;
`;

const ResetButton4 = styled.img`
  width:200px;
  margin-top:15px;
  cursor:pointer;
`;

const TextInput1 = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  color: grey;
  margin-top: 10px;
  padding: 15px;
  font-size: 14px;
  font: 400 12px/22px Poppins, sans-serif;
  width: 100%; /* Ensure all inputs take full width of their container */
  box-sizing: border-box; /* Ensures padding is included in the width */
  @media (max-width: 991px) {
    width: 100%; /* Ensures responsiveness */
    padding: 10px;
  }
`;
const TextInput2 = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  color: grey;
  margin-top: 10px;
  padding: 15px;
  font-size: 14px;
  font: 400 12px/22px Poppins, sans-serif;
  width: 49%; /* Ensure all inputs take full width of their container */
  box-sizing: border-box; /* Ensures padding is included in the width */
  @media (max-width: 991px) {
    width: 100%; /* Ensures responsiveness */
    padding: 10px;
  }
`;

const ActionButton = styled.img`
  background: none;
  border: none;

  cursor: pointer;
  padding: 0;
  gap:20px;
  width: 180px;
  img {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
  }
`;

const StyledMainContent = styled.div`
  padding-top: 20px;
  background-color: #1e1e1e;
  /* Add more styles as needed */
`;
const StyledAccountDeletionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 80px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const StyledDeletionTitle = styled.h1`
  letter-spacing: -0.2px;
  font: 700 20px/140% Poppins, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const StyledDeletionWarning = styled.p`
 font: 400 14px/100% Poppins, sans-serif;
  margin-top: 8px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
   @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 200px;
  background-color: #312f2f;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
   @media (max-width: 991px) {
    width: 100%;
     flex-direction: row;
     padding:0px;
     display: flex;
}
`;





const NavItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "white" : "white")};
  background-color: ${(props) => (props.selected ? "#3b82f6" : "transparent")};
  padding: 10px;
  border-radius: 0px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  
`;
const MainContent = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  background-color: #1e1e1e;
  padding: 20px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;











const FooterWrapper = styled.div`
  background-color: #312f2f;
  color: white;
  padding: 20px;
`;

export default Settings;
