import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Billie } from "../../assets/images";
import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import axios from "axios";
import { getToken, clearToken } from "../utils";
import config from "../../config";

import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";

const Youtube_song = () => {
  const [youSong, setyouSong] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = getToken();
  if (token == null) {
    navigate("/");
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSongUrl, setSelectedSongUrl] = useState("");

  const youtubeLinks = [
    "https://www.youtube.com/watch?v=d52MZJw-hIE&ab_channel=AcousticSelection",
    "https://youtu.be/xFle98bYj4I",
    "https://youtu.be/rdZSptFT5Tg?t=1",
    "https://youtu.be/uSkqSEnGALw",
    "https://youtu.be/qAlyjGrThGo",
    "https://youtu.be/Y2H_0H4buCU",
    "https://youtu.be/uqbTfrbxHAs",
  ];

  const getVideoIdFromUrl = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const fetchVideoDetails = async () => {
    const results = await Promise.all(
      youtubeLinks.map(async (url) => {
        const videoId = getVideoIdFromUrl(url);
        const response = await fetch(
          `https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`
        );
        const data = await response.json();
        return {
          youtube_id: videoId,
          title: data.title,
          image: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
          url,
        };
      })
    );
    setyouSong(results);
    setLoading(false);
  };

  useEffect(() => {
    fetchVideoDetails();
  }, []);

  const handleSongyoutubecard = (url) => {
    setSelectedSongUrl(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSongUrl("");
  };
  const getYoutubeVideoID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <>
      <Pre_nav />

      <SongListContainer>
        <MainTitle> Learn to play the guitar</MainTitle>
        <Overlay loading={loading}>
          <Spinner />
        </Overlay>
        <SongGrid>
          {youSong.map((youSongs) => (
            <SongCardContainer
              onClick={() => handleSongyoutubecard(youSongs.url)}
            >
              <AlbumCover loading="lazy" src={youSongs.image} />
              <SongInfo>
                <SongTitle>{youSongs.title}</SongTitle>
              </SongInfo>
            </SongCardContainer>
          ))}
        </SongGrid>
      </SongListContainer>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="YouTube Video"
        style={{
          content: {
            width: "560px",
            height: "315px",
            margin: "auto",
            padding: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        {selectedSongUrl && (
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${getYoutubeVideoID(
              selectedSongUrl
            )}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </ReactModal>
      <Footer />
    </>
  );
};

export default Youtube_song;

const SongListContainer = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  padding: 20px 32px;
  background-color: #020f12;
  height: 100%;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: left;
  letter-spacing: -0.24px;
  font: 600 24px/160% "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SongGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 991px) {
    gap: 10px;
  }
`;

const SongCardContainer = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 258px;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
  }
`;

const AlbumCover = styled.img`
  aspect-ratio: 1.27;

  width: 100%;
  border-radius: 8px 8px 0 0;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

const SongInfo = styled.div`
  border: 1px solid rgba(222, 227, 235, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px 0 18px;
  border-radius: 0 0 8px 8px;
`;

const SongTitle = styled.h3`
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 2); // Line height * number of lines
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

const SongArtist = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0;
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingText = styled.div`
  text-align: center;
  color: #fff;
`;
