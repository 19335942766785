import React from "react";
import styled from "styled-components";


const WarningModal = () => {
  return (
    <ModalContainer>
       <HeaderContainer>
      <WarningText>Warning</WarningText>
      <CloseIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/759fc87e921a32c58d0640e232745a4fc62880819b654e4da0eccff5929a76c2?apiKey=2d7c5855cb2f42a1b4e6163799d67ecc&" alt="Close" />
      </HeaderContainer>
      <MessageText>
      Downgrading to the free version of our service means you will lose access to your saved songs.
     </MessageText>
      <ActionButton primary>Downgrade</ActionButton>
      <ActionButton secondary>Cancel</ActionButton>
    </ModalContainer>
  );
};
const ActionButton = ({ children, primary, secondary, ...props }) => {
    return (
      <StyledButton primary={primary} secondary={secondary} {...props}>
        {children}
      </StyledButton>
    );
  };

  const StyledButton = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  align-self: center;
  white-space: nowrap;
  padding: 17px 30px;
  margin-top: ${props => props.secondary ? "22px" : "54px"};
  background-color: ${props => props.primary ? "#428bca" : "transparent"};
  color: ${props => props.primary ? "#fff" : "#717171"};
  border: none;
  cursor: pointer;
`;

const MessageText = styled.p`
  letter-spacing: -0.16px;
  margin-top: 64px;
  font: 500 16px/24px Poppins, sans-serif;
`;


const HeaderContainer = styled.header`
  align-self: flex-end;
  display: flex;
  width: 227px;
  max-width: 100%;
  gap: 20px;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
`;

const WarningText = styled.h2`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 12px;
  stroke-width: 2px;
  stroke: #000;
  margin: auto 0;
  border: 2px solid #000;
`;

const ModalContainer = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  font-size: 18px;
  color: #0e0402;
  font-weight: 600;
  text-align: center;
  padding: 29px 24px 77px;
`;

export default WarningModal;