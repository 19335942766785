import React, { useState, useEffect, useRef } from "react";

import { styled, keyframes } from "styled-components";
import {
    Circle2,
    SpinnerContainer,
    Logo,
    LoadingOverlay1,
    LoadingText2,
    SongInfo2,
    SongTitle2,
    Spinner,
    Overlay,
  } from "../../assets/styles/premium_user_styled";
  import {  playlist_image } from "../../assets/images";

import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import axios from "axios";
import { getToken, getLoadingStatus, clearToken } from "../utils";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";



const All_Playlist = () => {


    const token = getToken();

    const navigate = useNavigate();
    if (token == null) {
        navigate("/");
    }

    

    const [playlist, setplaylist] = useState([]);
    const [loading, setLoading] = useState(true);




    useEffect(() => {
        get_playlist();
    }, []);


    const get_playlist = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/playlists/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const playlistsData = response.data.map((item) => {

                    return {
                       
                        name: item.name,
                        song_image_url: item.song_image_url,
                        tracks: item.total_songs,
                        id: item.playlist_id,

                    };
                });
                setplaylist(playlistsData);
                setLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
                navigate("/");
            }
        }
    };



    const handleSongCardClick = async (id,name) => {
       const xyz = "all_playlist";
        window.scrollTo(0, 0);
        navigate("/songs", { state: { result1: id , result2:name ,nav1:xyz } });

    };






    return (
        <AppContainer>
            <Pre_nav />
            <Overlay loading={loading}>
                <Spinner />

            </Overlay>
            <SongListContainer>
                <Header>
                    <MainTitle>All Playlist</MainTitle>

                </Header>
                <SongGridall>




                    <SongGrid>
                        {playlist.map((playlists) => (
                            <SongCardContainer
                                key={playlists.name}
                                onClick={() => handleSongCardClick(playlists.id,playlists.name)}
                            > 
                            <AlbumCover2
                            loading="lazy"
                            src={playlists.song_image_url ? playlists.song_image_url : playlist_image}
                            alt={playlists.name}
                          />

                                <SongInfo2>
                                    <SongTitle4>{playlists.name}</SongTitle4>
                                    <Songdate>{playlists.tracks} Tracks</Songdate>
                                </SongInfo2>
                            </SongCardContainer>
                        ))}
                    </SongGrid>
                </SongGridall>

            </SongListContainer>

            <ToastContainer />
            <Footer />

        </AppContainer>
    );
};

export default All_Playlist;

const Songdate = styled.h5`
text-align: center;
font-family: 'Poppins', sans-serif;
font-weight: 500;
margin: 0;
margin-top:7px;
font-size: 13px; 
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
line-height: 1.2; 
max-height: calc(1.2em * 2); 


@media (max-width: 991px) {
  font-size: 12px;
}
`;

const SongTitle4 = styled.h3`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 2); // Line height * number of lines
  
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

const SongGridall = styled.section`
  display: flex;
  flex-direction: row;
  gap: 20px; // Add a gap between the grids if needed
  @media (max-width: 991px) {
    flex-direction: column; // Stack vertically on small screens
    gap: 10px;
  }
`;




const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

`;



const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:12px;
`;


const SongListContainer = styled.main`
  display: flex;
  flex-direction: column;
   min-height: 80vh;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  padding: 20px 32px;
  background-color: #020f12;
  height: 100%;
  @media (max-width: 991px) {
     padding: 0px;
     width:100%
  }
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: left;
  letter-spacing: -0.24px;
  font: 600 24px/160% "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  @media (max-width: 991px) {
   letter-spacing: -0.24px;
  font: 600 18px/160% "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  }
`;

const SongGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-left:12px;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 991px) {
    gap: 5px;
  }
`;

const SongCardContainer = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 258px;
  position: relative;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
  }
`;

const AlbumCover2 = styled.img`
  aspect-ratio: 1.27;
 
  width: 100%;
  border-radius: 8px 8px 0 0;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

