import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { new_logo, white_close } from "../../assets/images"; // Make sure this is imported correctly
import axios from "axios";
import { getToken, clearToken } from "../utils";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const FeedbackPopup = ({ onClose }) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null); // To store selected emoji index
  const [hoveredEmoji, setHoveredEmoji] = useState(null); // To store hovered emoji index
  const [selectedRating, setSelectedRating] = useState(null); // To store selected rating
  const [feedbackText, setFeedbackText] = useState(""); // To store feedback text
  const token = getToken();
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null);

  // Define your emojis with SVG paths
  const emojis = [
    {
      src: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 62 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.954 60.9436C47.6808 60.9436 61.2406 47.3838 61.2406 30.657C61.2406 13.9301 47.6808 0.370361 30.954 0.370361C14.2271 0.370361 0.667358 13.9301 0.667358 30.657C0.667358 47.3838 14.2271 60.9436 30.954 60.9436Z"
            fill={
              selectedEmoji === 0 || hoveredEmoji === 0 ? "#E23E44" : "#E0E0E0"
            }
          />
          <path
            d="M39.8113 25.4982C39.8113 27.7052 41.1663 29.4932 42.8373 29.4932C44.5083 29.4932 45.8633 27.7042 45.8633 25.4982C45.8633 23.2922 44.5083 21.5032 42.8373 21.5032C41.1663 21.5032 39.8113 23.2912 39.8113 25.4982Z"
            fill="#263238"
          />
          <path
            d="M18.8373 29.6768C20.5085 29.6768 21.8633 27.8881 21.8633 25.6818C21.8633 23.4754 20.5085 21.6868 18.8373 21.6868C17.1661 21.6868 15.8113 23.4754 15.8113 25.6818C15.8113 27.8881 17.1661 29.6768 18.8373 29.6768Z"
            fill="#263238"
          />
          <path
            d="M22.3883 44.2239C23.4483 40.4159 27.1963 37.5219 31.1903 37.6729C35.1683 37.8229 38.7543 40.9059 39.5273 44.8039C39.7593 45.9709 37.9693 46.4709 37.7373 45.2979C37.1263 42.2189 34.3873 39.6549 31.1903 39.5299C28.0103 39.4059 25.0243 41.6779 24.1783 44.7179C23.8593 45.8669 22.0673 45.3789 22.3883 44.2239Z"
            fill="#263238"
          />
        </svg>
      ),
      alt: "Very Dissatisfied",
    },
    {
      src: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 61 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.5279 60.9443C47.2551 60.9443 60.8152 47.3842 60.8152 30.657C60.8152 13.9298 47.2551 0.36969 30.5279 0.36969C13.8007 0.36969 0.240601 13.9298 0.240601 30.657C0.240601 47.3842 13.8007 60.9443 30.5279 60.9443Z"
            fill={
              selectedEmoji === 1 || hoveredEmoji === 1 ? "#E84D00" : "#E0E0E0"
            }
          />
          <path
            d="M40.546 25.5112C40.546 27.7172 41.901 29.5062 43.572 29.5062C45.243 29.5062 46.598 27.7172 46.598 25.5112C46.598 23.3042 45.243 21.5162 43.572 21.5162C41.901 21.5162 40.546 23.3042 40.546 25.5112Z"
            fill="#263238"
          />
          <path
            d="M15.374 25.5112C15.374 27.7172 16.729 29.5062 18.4 29.5062C20.071 29.5062 21.426 27.7172 21.426 25.5112C21.426 23.3042 20.071 21.5162 18.4 21.5162C16.729 21.5162 15.374 23.3042 15.374 25.5112Z"
            fill="#263238"
          />
          <path
            d="M37.9799 43.6808C37.7259 43.6808 37.4719 43.5838 37.2769 43.3918C35.4759 41.6098 32.3859 40.9168 29.0119 41.5338C27.3829 41.8328 25.8089 42.3679 24.3349 43.1239C23.8429 43.3769 23.241 43.1818 22.989 42.6898C22.737 42.1988 22.931 41.5959 23.422 41.3439C25.07 40.4979 26.8299 39.9009 28.6519 39.5669C32.7309 38.8219 36.3849 39.6958 38.6839 41.9698C39.0769 42.3588 39.08 42.9908 38.692 43.3838C38.496 43.5828 38.2379 43.6808 37.9799 43.6808Z"
            fill="#263238"
          />
        </svg>
      ),
      alt: "Dissatisfied",
    },
    {
      src: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 62 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.1013 60.943C47.8278 60.943 61.3873 47.3835 61.3873 30.657C61.3873 13.9305 47.8278 0.371002 31.1013 0.371002C14.3748 0.371002 0.815308 13.9305 0.815308 30.657C0.815308 47.3835 14.3748 60.943 31.1013 60.943Z"
            fill={
              selectedEmoji === 2 || hoveredEmoji === 2 ? "#F3DF00" : "#E0E0E0"
            }
          />
          <path
            d="M41.1353 25.506C41.1353 27.712 42.4903 29.501 44.1613 29.501C45.8323 29.501 47.1873 27.712 47.1873 25.506C47.1873 23.299 45.8323 21.511 44.1613 21.511C42.4903 21.51 41.1353 23.299 41.1353 25.506Z"
            fill="#263238"
          />
          <path
            d="M18.9893 29.501C20.6605 29.501 22.0153 27.7124 22.0153 25.506C22.0153 23.2996 20.6605 21.511 18.9893 21.511C17.318 21.511 15.9633 23.2996 15.9633 25.506C15.9633 27.7124 17.318 29.501 18.9893 29.501Z"
            fill="#263238"
          />
          <path
            d="M42.6853 44.2283C35.2033 44.2283 27.7213 44.2283 20.2393 44.2283C19.0443 44.2283 19.0423 42.3713 20.2393 42.3713C27.7213 42.3713 35.2033 42.3713 42.6853 42.3713C43.8793 42.3713 43.8813 44.2283 42.6853 44.2283Z"
            fill="#263238"
          />
        </svg>
      ),
      alt: "Dissatisfied",
    },
    {
      src: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.0442 63.314C49.5279 63.314 63.7012 49.1407 63.7012 31.657C63.7012 14.1733 49.5279 0 32.0442 0C14.5605 0 0.387207 14.1733 0.387207 31.657C0.387207 49.1407 14.5605 63.314 32.0442 63.314Z"
            fill={
              selectedEmoji === 3 || hoveredEmoji === 3 ? "#8EC63A" : "#E0E0E0"
            }
          />
          <path
            d="M42.5322 26.2717C42.5322 28.5777 43.9482 30.4477 45.6952 30.4477C47.4422 30.4477 48.8582 28.5777 48.8582 26.2717C48.8582 23.9657 47.4422 22.0957 45.6952 22.0957C43.9482 22.0957 42.5322 23.9657 42.5322 26.2717Z"
            fill="#263238"
          />
          <path
            d="M16.2212 26.2717C16.2212 28.5777 17.6372 30.4477 19.3842 30.4477C21.1312 30.4477 22.5472 28.5777 22.5472 26.2717C22.5472 23.9657 21.1312 22.0957 19.3842 22.0957C17.6372 22.0957 16.2212 23.9657 16.2212 26.2717Z"
            fill="#263238"
          />
          <path
            d="M41.5651 39.1614C40.6201 43.1834 36.8271 46.3654 32.6491 46.3764C28.4881 46.3884 24.6121 43.3204 23.6391 39.2824C23.3481 38.0734 25.1951 37.4764 25.4881 38.6914C26.2571 41.8814 29.2261 44.4434 32.5701 44.4384C35.8961 44.4334 38.9191 41.9334 39.6741 38.7224C39.9591 37.5074 41.8511 37.9414 41.5651 39.1614Z"
            fill="#263238"
          />
        </svg>
      ),
      alt: "Dissatisfied",
    },
    {
      src: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 63 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.517 62.4727C48.536 62.4727 62.3327 48.676 62.3327 31.657C62.3327 14.638 48.536 0.841309 31.517 0.841309C14.498 0.841309 0.701294 14.638 0.701294 31.657C0.701294 48.676 14.498 62.4727 31.517 62.4727Z"
            fill={
              selectedEmoji === 4 || hoveredEmoji === 4 ? "#0CD50C" : "#E0E0E0"
            }
          />
          <path
            d="M41.4442 26.4141C41.4442 28.6591 42.8232 30.4791 44.5232 30.4791C46.2242 30.4791 47.6022 28.6591 47.6022 26.4141C47.6022 24.1691 46.2232 22.3491 44.5232 22.3491C42.8222 22.3491 41.4442 24.1691 41.4442 26.4141Z"
            fill="#263238"
          />
          <path
            d="M15.8312 26.4141C15.8312 28.6591 17.2102 30.4791 18.9102 30.4791C20.6112 30.4791 21.9892 28.6591 21.9892 26.4141C21.9892 24.1691 20.6102 22.3491 18.9102 22.3491C17.2102 22.3491 15.8312 24.1691 15.8312 26.4141Z"
            fill="#263238"
          />
          <path
            d="M20.8932 36.9995C20.8932 43.0743 25.6532 47.9995 31.5262 47.9995C37.3992 47.9995 42.1592 43.0743 42.1592 36.9995H20.8932Z"
            fill="#263238"
          />
          <path
            d="M25.9933 46.3846C25.9933 46.3846 28.0923 43.7789 31.5263 43.7789C34.9603 43.7789 37.0723 46.3846 37.0723 46.3846C37.0723 46.3846 31.9153 49.7096 25.9933 46.3846Z"
            fill="white"
          />
        </svg>
      ),
      alt: "Dissatisfied",
    },
  ];

  // const [isHovered, setIsHovered] = useState(false);
  // const timeoutRef = useRef(null);

  // Handle mouse entering and leaving the popup
  const handleMouseEnter = () => {
    setIsHovered(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the timeout if the user hovers over the form
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    startCloseTimer(); // Restart the timer when the mouse leaves the form
  };

  // Function to start the timer for auto-closing the form
  const startCloseTimer = () => {
    timeoutRef.current = setTimeout(() => {
      if (!isHovered) {
        setIsClosing(true);
        setTimeout(() => {
          onClose(); // Call the passed onClose function after animation completes
        }, 300);
        onClose(); // Close the form if the user has not hovered over it within 10 seconds
      }
    }, 10000); // 10 seconds
  };

  useEffect(() => {
    startCloseTimer(); // Start the timer when the component mounts

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clean up the timeout when the component unmounts
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!isHovered) {
  //     timeoutRef.current = setTimeout(() => {
  //       close_popup(); // Close the popup after 10 seconds
  //     }, 10000);
  //   }

  //   // Clear the timeout if the popup is hovered
  //   return () => clearTimeout(timeoutRef.current);
  // }, [isHovered]);

  const ratings = Array.from({ length: 10 }, (_, i) => i + 1); // 1 to 10 rating

  // const handleMouseEnter = () => setIsHovered(true);
  // const handleMouseLeave = () => setIsHovered(false);

  const handleEmojiClick = (index) => {
    // Toggle selection if the clicked emoji is already selected
    setSelectedEmoji((prev) => (prev === index ? null : index));
  };

  const handleratingClick = (index) => {
    setSelectedRating((prev) => (prev === index ? null : index));
  };

  const close_popup = () => {
    const currentDate = new Date().toISOString();
    Cookies.set("feedbackClosedWithoutSubmit", currentDate, { expires: 1 });

    setIsClosing(true);
    setTimeout(() => {
      onClose(); // Call the passed onClose function after animation completes
    }, 300);
  };

  const submit_feed = async (e) => {
    e.preventDefault();

    try {
      const emojiss = selectedEmoji + 1;
      const response = await axios.post(
        `${config.apiUrl}/users/feedback`,
        {
          text: feedbackText || "",
          emoji_score: emojiss || "",
          score: selectedRating || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const currentDate = new Date().toISOString();
        Cookies.set("feedbackSubmitted", "true", { expires: 7 });
        setIsClosing(true);
        setTimeout(() => {
          onClose();
        }, 300);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  return (
    <PopupContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isClosing={isClosing}
    >
      <Logo src={new_logo} alt="Company Logo" />
      <ContentWrapper>
        <Title>Tell us what you think about Jammin.ai</Title>

        <EmojiContainer>
          {emojis.map((emoji, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredEmoji(index)}
              onMouseLeave={() => setHoveredEmoji(null)}
              onClick={() => handleEmojiClick(index)}
              style={{
                cursor: "pointer",
                width: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {emoji.src} {/* Render the SVG directly */}
            </div>
          ))}
        </EmojiContainer>
      </ContentWrapper>

      <FormContainer>
        <FeedbackTextArea>
          <Label htmlFor="feedbackText">Please leave your feedback below</Label>
          <TextArea
            id="feedbackText"
            placeholder="Write here"
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
        </FeedbackTextArea>

        <RatingQuestion>
          <QuestionText>
            How likely is it that you will recommend Jammin.ai to your family
            and friends
          </QuestionText>
          <ScaleContainer>
            {ratings.map((rating, index) => (
              <RatingButton
                key={rating}
                isSelected={selectedRating === rating}
                onClick={() => handleratingClick(rating)}
              >
                {rating}
              </RatingButton>
            ))}
          </ScaleContainer>
        </RatingQuestion>
        <CloseButton onClick={close_popup} src={white_close} alt="Close" />
        <SubmitButton onClick={(e) => submit_feed(e)}>
          Submit Feedback
        </SubmitButton>
      </FormContainer>
    </PopupContainer>
  );
};

const ScaleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const RatingButton = styled.div`
  border-radius: 2px;
  background-color: ${(props) => (props.isSelected ? "#0cd50c" : "#373737")};
  min-height: 24px;
  width: 25px;
  padding: 7px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #0cd50c;
  }
  @media (max-width: 991px) {
    min-height: 18px;
    width: 20px;
    padding: 5px 3px;
    font-size: 12px;
    white-space: normal;
  }
`;

const FormContainer = styled.form`
  align-self: stretch;
  display: flex;
  margin-top: 15px;
  width: 100%;
  flex-direction: column;
  color: #ffffff;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const FeedbackTextArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Label = styled.label`
  letter-spacing: -0.18px;
  text-align: center;
  align-self: flex-start;
  font: 600 16px/1.2 Poppins, sans-serif;
`;

const TextArea = styled.textarea`
  border-radius: 8px;
  background-color: #3a3b3b;
  margin-top: 10px;
  min-height: 50px;
  width: 87%;
  padding: 20px 24px;
  font: 500 16px Inter, sans-serif;
  border: none;
  color: #ffffff;
  resize: vertical;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;
const RatingQuestion = styled.div`
  display: flex;

  width: 100%;
  flex-direction: column;
  letter-spacing: -0.18px;
  font: 600 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const QuestionText = styled.div`
  line-height: 12px;
  margin-top: 20px;
  font: 600 16px/1.2 Poppins, sans-serif;
  margin-bottom: 15px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

// Slide out to bottom
const slideOut = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

const PopupContainer = styled.section`
  background-color: #282828;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  max-width: 364px;
  max-height: 450px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 24px;
  margin-right: 10px;
  margin-bottom: 10px;

  animation: ${(props) => (props.isClosing ? slideOut : slideIn)} 0.5s ease-out;
  @media (max-width: 991px) {
    justify-content: center;
    align-items: center;
    bottom: unset;
    right: unset;
    width: 80%;
    margin-right: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Logo = styled.img`
  aspect-ratio: 3.16;
  object-fit: contain;
  object-position: center;
  width: 142px;
  max-width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 0px;
  width: 100%;
  max-width: 558px;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  color: #ffffff;
  letter-spacing: -0.24px;
  text-align: center;
  font: 600 16px/1.2 Poppins, sans-serif;
  margin-bottom: 20px;
`;

const CloseButton = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  position: absolute;
  right: 28px;
  top: 33px;
  cursor: pointer;
  @media (max-width: 991px) {
    top: 20px;
    right: 20px;
  }
`;

const SubmitButton = styled.button`
  border-radius: 4px;
  background-color: #0072d5;
  margin: 20px auto 0; /* Centers the button horizontally */
  min-height: 56px;
  width: 258px;
  color: #ffffff;
  padding: 18px 12px;
  font: 600 14px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: normal;
  }
`;

const EmojiContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;

const EmojiImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 60px;
  cursor: pointer;
`;

export default FeedbackPopup;
